import { BooleanInput, Create, SimpleForm, TextInput } from 'react-admin';

const OccasionCreate = () => {
    return (
        <Create>
            <SimpleForm sx={{ maxWidth: '26em' }}>
                <TextInput source="name" fullWidth isRequired />
                <TextInput source="type" fullWidth defaultValue="occasion" disabled />
                <BooleanInput source="isActive" />
            </SimpleForm>
        </Create>
    );
};

export default OccasionCreate;
