import { PropsWithChildren, useState } from 'react';
import { Button, EditContextProvider, SimpleForm, useEditController } from 'react-admin';
import { Dialog, DialogActions, DialogTitle, IconButton } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import CancelIcon from '@mui/icons-material/Cancel';
import UpdateConfirmButton from './UpdateConfirmButton';
interface Props extends PropsWithChildren {
    title?: string;
    id: string;
    resource: string;
}

const EditDialogButton = (props: Props) => {
    const { title, id, resource, children } = props;
    const [showDialog, setShowDialog] = useState(false);

    const onSuccess = () => {
        setShowDialog(false);
    };
    const onError = (error: any) => {
        setShowDialog(false);
    };
    const DialogController = useEditController({
        resource,
        id,
        redirect: false,
        mutationMode: 'pessimistic',
        mutationOptions: { onSuccess, onError },
    });

    const handleClick = () => {
        setShowDialog(true);
    };
    const handleCloseClick = () => {
        setShowDialog(false);
    };

    return (
        <>
            <IconButton
                aria-label="delete"
                size="small"
                onClick={handleClick}
                sx={{
                    marginLeft: '0.3em',
                }}
            >
                <EditIcon fontSize="inherit" />
            </IconButton>
            <Dialog fullWidth open={showDialog} onClose={handleCloseClick} aria-label="Create">
                <DialogTitle>Update {title}</DialogTitle>
                <EditContextProvider value={DialogController}>
                    <SimpleForm warnWhenUnsavedChanges toolbar={false}>
                        {children}
                        <DialogActions
                            sx={{
                                width: '100%',
                                display: 'flex',
                                justifyItems: 'flex-end',
                            }}
                        >
                            <Button label="Cancel" onClick={handleCloseClick}>
                                <CancelIcon />
                            </Button>
                            <UpdateConfirmButton />
                        </DialogActions>
                    </SimpleForm>
                </EditContextProvider>
            </Dialog>
        </>
    );
};

export default EditDialogButton;
