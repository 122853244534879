import { Box, Grid, IconButton, Input } from '@mui/material';
import { useState } from 'react';
import { useInput, useNotify } from 'react-admin';

import AddBoxIcon from '@mui/icons-material/AddBox';
import CancelIcon from '@mui/icons-material/Cancel';

const ZipCodeInput = () => {
    const notify = useNotify();
    const { field } = useInput({ source: 'zipcodes' });

    const [zipcode, setZipcode] = useState('');

    const handleOnchage = (e: any) => {
        setZipcode(e.target.value);
    };

    const handleDelete = (zipcode: string) => {
        const value = field.value.filter((zc: string) => zc !== zipcode);
        field.onChange(value);
    };

    const handleSubmit = () => {
        if (!zipcode) {
            notify('Zipcode input is Empty!', { type: 'error' });
            return;
        }
        if (field.value.indexOf(zipcode) > -1) {
            notify('Zipcode input already exist!', { type: 'error' });
            return;
        }
        const value = [...field.value, zipcode];
        setZipcode('');

        field.onChange(value);
    };
    return (
        <>
            <Grid container>
                <Grid container spacing={1}>
                    <Grid item xs={12}>
                        Zipcodes
                    </Grid>
                    {field?.value ? (
                        field?.value?.map((zipcode: string) => (
                            <Grid item key={zipcode}>
                                <Box
                                    key={zipcode}
                                    sx={{
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        padding: '2px 6px',
                                        fontWeight: 'bold',
                                        fontSize: '0.875em',
                                        backgroundColor: '#0033CC',
                                        color: '#fff',
                                        borderRadius: '5px',
                                        cursor: 'pointer',
                                        display: 'flex',
                                    }}
                                >
                                    {zipcode}
                                    <IconButton
                                        aria-label="Delete zipcode"
                                        size="small"
                                        onClick={() => handleDelete(zipcode)}
                                        sx={{
                                            marginLeft: '0.1em',
                                        }}
                                    >
                                        <CancelIcon fontSize="inherit" />
                                    </IconButton>
                                </Box>
                            </Grid>
                        ))
                    ) : (
                        <></>
                    )}
                </Grid>

                <Grid container>
                    <Grid item>
                        <Input onChange={handleOnchage} value={zipcode} />
                    </Grid>
                    <Grid item>
                        <IconButton
                            aria-label="Add zipcode"
                            onClick={() => handleSubmit()}
                            size="medium"
                            sx={{
                                marginLeft: '0.1em',
                                color: '#0033CC',
                            }}
                        >
                            <AddBoxIcon fontSize="inherit" />
                        </IconButton>
                    </Grid>
                </Grid>
            </Grid>
        </>
    );
};

export default ZipCodeInput;
