import { MouseEventHandler, useState, PropsWithChildren, FC } from 'react';
import {
    Button,
    ButtonProps,
    CreateContextProvider,
    SaveButton,
    SimpleForm,
    useCreateController,
    useNotify,
    useRefresh,
} from 'react-admin';
import AddIcon from '@mui/icons-material/Add';
import CancelIcon from '@mui/icons-material/Cancel';
import { Dialog, DialogActions, DialogTitle } from '@mui/material';

interface Props extends PropsWithChildren {
    title?: string;
    resource: string;
    CreateBtn?: FC<ButtonProps>;
}

const CreateDialogButton = (props: Props) => {
    const { resource, title = 'New', children, CreateBtn = ButtonCreateDefault } = props;

    const [showDialog, setShowDialog] = useState(false);
    const notify = useNotify();
    const refresh = useRefresh();

    const onSuccess = () => {
        setShowDialog(false);
        notify(`Created Element`);
        refresh();
    };

    const createController = useCreateController({ resource, redirect: false, mutationOptions: { onSuccess } });

    const handleClick: MouseEventHandler<HTMLButtonElement> = () => {
        setShowDialog(true);
    };
    const handleCloseClick: MouseEventHandler<HTMLButtonElement> = () => {
        setShowDialog(false);
    };

    return (
        <>
            <CreateBtn onClick={handleClick} />
            <Dialog fullWidth open={showDialog} onClose={handleCloseClick} aria-label="Create">
                <DialogTitle>Create {title}</DialogTitle>
                <CreateContextProvider value={createController}>
                    <SimpleForm toolbar={false}>
                        {children}
                        <DialogActions
                            sx={{
                                width: '100%',
                                display: 'flex',
                                justifyItems: 'flex-end',
                            }}
                        >
                            <Button label="Cancel" onClick={handleCloseClick}>
                                <CancelIcon />
                            </Button>
                            <SaveButton label="Create" />
                        </DialogActions>
                    </SimpleForm>
                </CreateContextProvider>
            </Dialog>
        </>
    );
};

const ButtonCreateDefault: FC<ButtonProps> = ({ onClick }) => {
    return (
        <Button onClick={onClick} label="Create">
            <AddIcon />
        </Button>
    );
};

export default CreateDialogButton;
