import { Box, Card, CardContent, Grid, Stack, Typography } from '@mui/material';
import {
    DateField,
    Edit,
    FormTab,
    Labeled,
    NumberField,
    ReferenceArrayField,
    ReferenceField,
    SingleFieldList,
    TabbedForm,
    TextField,
    TextInput,
} from 'react-admin';
import ToolbarCustom from '../components/ToolbarCustom';
const UserEdit = () => {
    return (
        <Edit component="div">
            <Box maxWidth="50em">
                <Card>
                    <UserTabble />
                </Card>
            </Box>
        </Edit>
    );
};

const UserTabble = () => {
    return (
        <TabbedForm toolbar={<ToolbarCustom />}>
            <FormTab label="User" sx={{ maxWidth: '20em' }}>
                <TextInput source="id" disabled fullWidth />
                <TextInput source="name" fullWidth />
                <TextInput label="Email" source="username" fullWidth />
            </FormTab>

            <FormTab label="Most recent order">
                <MostRecentOrder />
            </FormTab>

            <FormTab label="Cart">
                <ReferenceField source="order.cart" reference="carts">
                    <TextField source="status" />
                </ReferenceField>
            </FormTab>
        </TabbedForm>
    );
};
const MostRecentOrder = () => {
    return (
        <CardContent>
            <Stack spacing={5}>
                <Grid container spacing={7}>
                    <Grid item xs={10} sm={10} md={4}>
                        <Labeled>
                            <DateField label="Delivery Date" source="order.deliveryDate" />
                        </Labeled>
                    </Grid>
                    <Grid item xs={10} sm={10} md={4}>
                        <Labeled>
                            <TextField label="Status" source="order.status" />
                        </Labeled>
                    </Grid>
                    <Grid item xs={10} sm={10} md={4}>
                        <ReferenceField source="order.cart" reference="carts">
                            <Labeled>
                                <NumberField label="Total" source="total" />
                            </Labeled>
                        </ReferenceField>
                    </Grid>
                </Grid>
                <ReferenceField source="order.cart" reference="carts">
                    <Grid item xs={10} sm={10} md={4}>
                        <Labeled>
                            <ReferenceArrayField label="Merchandise/item" source="items" reference="cartItems">
                                <SingleFieldList>
                                    <ReferenceField source="item" reference="merchandises">
                                        <Typography mt={1}>{<TextField source="name" />}</Typography>
                                        <Grid item xs={10} sm={10} md={4} mb={1}>
                                            <TextField source="description" />
                                        </Grid>
                                    </ReferenceField>
                                </SingleFieldList>
                            </ReferenceArrayField>
                        </Labeled>
                    </Grid>
                </ReferenceField>
            </Stack>
        </CardContent>
    );
};
export default UserEdit;
