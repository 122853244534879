import { Box, Stack } from '@mui/material';
import { Create, required, SimpleForm, TextInput, useInput } from 'react-admin';
import ImageInputS3 from '../components/ImageInput';

const PhotoCreate = () => {
    return (
        <Create redirect="list">
            <SimpleForm sx={{ maxWidth: '50em' }}>
                <TextInput autoFocus source="url" validate={required()} fullWidth disabled />
                <ImageForm source="url" />
            </SimpleForm>
        </Create>
    );
};

const ImageForm = ({ source }: { source: string }) => {
    const { field } = useInput({ source });
    const handleUploadDone = (url: string) => {
        field.onChange(url);
    };

    return (
        <Stack spacing={3}>
            <ImageInputS3 uploadDone={handleUploadDone} />
            {field.value ? (
                <Box
                    component="img"
                    sx={{
                        maxWidth: { xs: 350, md: 250 },
                    }}
                    src={field.value}
                    srcSet={field.value}
                    loading="lazy"
                />
            ) : (
                ''
            )}
        </Stack>
    );
};
export default PhotoCreate;
