import UserList from './UserList';
import UserEdit from './UserEdit';
import UserIcon from '@mui/icons-material/People';

const users = {
    list: UserList,
    edit: UserEdit,
    icon: UserIcon,
};

export default users;
