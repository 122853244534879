import { Button } from '@mui/material';
import ThumbUp from '@mui/icons-material/ThumbUp';
import { useNotify, useRecordContext, useRedirect, useUpdate } from 'react-admin';
const RejectButton = () => {
    const notify = useNotify();
    const redirectTo = useRedirect();
    const record = useRecordContext();

    const [approve, { isLoading }] = useUpdate(
        'reviews',
        { id: record._id, data: { ...record, status: 'approved' } },
        {
            onSuccess: () => {
                notify('Approve Success', {
                    type: 'info',
                });
                redirectTo('/reviews');
            },
            onError: () => {
                notify('Approve Error', {
                    type: 'error',
                });
            },
        },
    );

    return record && record.status === 'pending' ? (
        <Button
            variant="outlined"
            color="primary"
            size="small"
            onClick={() => approve()}
            disabled={isLoading}
            startIcon={<ThumbUp sx={{ color: 'green' }} />}
        >
            APPROVE
        </Button>
    ) : (
        <span />
    );
};

export default RejectButton;
