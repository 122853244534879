import { Datagrid, ExportButton, List, TextField, TopToolbar, useListContext } from 'react-admin';

const ListActions = () => {
    const { total, isLoading } = useListContext();
    return (
        <TopToolbar>
            <ExportButton disabled={isLoading || total === 0} />
        </TopToolbar>
    );
};

const UserList = () => {
    return (
        <List actions={<ListActions />}>
            <Datagrid bulkActionButtons={false} rowClick="edit">
                <TextField source="name" />
                <TextField source="username" />
            </Datagrid>
        </List>
    );
};

export default UserList;
