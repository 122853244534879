import * as React from 'react';
import { Box, Grid, Tabs, Tab } from '@mui/material';
import {
    Loading,
    SimpleForm,
    TextInput,
    useEditController,
    EditContextProvider,
    NumberInput,
    SelectInput,
    required,
    email,
    EditControllerProps,
} from 'react-admin';
import ToolbarCustom from '../components/ToolbarCustom';
import TIMEZONES from './timeZone';

const Configuration = () => {
    const controllerProps = useEditController({ resource: 'shop', id: '5f4ae628b4a56b7c47b68a46', redirect: false });
    const [value, setValue] = React.useState(0);
    if (controllerProps.isLoading) return <Loading />;
    if (controllerProps.error) return <p>ERROR</p>;

    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setValue(newValue);
    };

    return (
        <Box sx={{ width: '100%' }}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                    <Tab label="Shop" {...a11yProps(0)} />
                    <Tab label="Address" {...a11yProps(1)} />
                </Tabs>
            </Box>
            <TabPanel value={value} index={0}>
                <Shop controllerProps={controllerProps} />
            </TabPanel>
            <TabPanel value={value} index={1}>
                <Address locationId={controllerProps.record['location']} />
            </TabPanel>
        </Box>
    );
};

const Shop = ({ controllerProps }: EditControllerProps) => {
    return (
        <EditContextProvider value={controllerProps}>
            <SimpleForm toolbar={<ToolbarCustom noDelete />}>
                <Grid container spacing={4}>
                    <Grid item xs={10} sm={10} md={6}>
                        <TextInput source="name" validate={required()} />
                    </Grid>
                    <Grid item xs={10} sm={10} md={6}>
                        <NumberInput source="cutoffTime" validate={required()} />
                    </Grid>
                </Grid>
                <Grid container spacing={4}>
                    <Grid item xs={10} sm={10} md={6}>
                        <TextInput source="phoneNumber" validate={required()} />
                    </Grid>
                    <Grid item xs={10} sm={10} md={6}>
                        <TextInput source="email" validate={email()} />
                    </Grid>
                </Grid>
                <Grid item xs={10} sm={10} md={8}>
                    <SelectInput fullWidth source="timezone" choices={TIMEZONES} validate={required()} />
                </Grid>
                <Grid container spacing={3}>
                    <Grid item xs={10} sm={10} md={6}>
                        <NumberInput source="storeHours.monStart" />{' '}
                    </Grid>
                    <Grid item xs={10} sm={10} md={6}>
                        <NumberInput source="storeHours.monEnd" />{' '}
                    </Grid>
                </Grid>
                <Grid container spacing={3}>
                    <Grid item xs={10} sm={10} md={6}>
                        <NumberInput source="storeHours.tueStart" />
                    </Grid>
                    <Grid item xs={10} sm={10} md={6}>
                        <NumberInput source="storeHours.tueEnd" />
                    </Grid>
                </Grid>
                <Grid container spacing={3}>
                    <Grid item xs={10} sm={10} md={6}>
                        <NumberInput source="storeHours.wedStart" />
                    </Grid>
                    <Grid item xs={10} sm={10} md={6}>
                        <NumberInput source="storeHours.wedEnd" />
                    </Grid>
                </Grid>
                <Grid container spacing={3}>
                    <Grid item xs={10} sm={10} md={6}>
                        <NumberInput source="storeHours.thuStart" />
                    </Grid>
                    <Grid item xs={10} sm={10} md={6}>
                        <NumberInput source="storeHours.thuEnd" />
                    </Grid>
                </Grid>
                <Grid container spacing={3}>
                    <Grid item xs={10} sm={10} md={6}>
                        <NumberInput source="storeHours.friStart" />
                    </Grid>
                    <Grid item xs={10} sm={10} md={6}>
                        <NumberInput source="storeHours.friEnd" />
                    </Grid>
                </Grid>
                <Grid container spacing={3}>
                    <Grid item xs={10} sm={10} md={6}>
                        <NumberInput source="storeHours.satStart" />
                    </Grid>
                    <Grid item xs={10} sm={10} md={6}>
                        <NumberInput source="storeHours.satEnd" />
                    </Grid>
                </Grid>
                <Grid container spacing={3}>
                    <Grid item xs={10} sm={10} md={6}>
                        <NumberInput source="storeHours.sunStart" />
                    </Grid>
                    <Grid item xs={10} sm={10} md={6}>
                        <NumberInput source="storeHours.sunEnd" />
                    </Grid>
                </Grid>
            </SimpleForm>
        </EditContextProvider>
    );
};

const Address = ({ locationId }: { locationId: string }) => {
    const controllerProps = useEditController({
        resource: 'locations',
        id: locationId,
        redirect: false,
    });
    if (controllerProps.isLoading) return <Loading />;

    if (controllerProps.error) return <p>ERROR</p>;

    return (
        <EditContextProvider value={controllerProps}>
            <SimpleForm toolbar={<ToolbarCustom noDelete />}>
                <Grid container spacing={3}>
                    <Grid item xs={10} sm={10} md={6}>
                        <TextInput source="address1" validate={required()} />
                    </Grid>
                    <Grid item xs={10} sm={10} md={6}>
                        <TextInput source="address2" />
                    </Grid>
                </Grid>
                <Grid container spacing={3}>
                    <Grid item xs={10} sm={10} md={6}>
                        <TextInput source="city" validate={required()} />
                    </Grid>
                    <Grid item xs={10} sm={10} md={6}>
                        <TextInput source="state" validate={required()} />
                    </Grid>
                </Grid>
                <Grid container spacing={3}>
                    <Grid item xs={10} sm={10} md={6}>
                        <TextInput source="zipcode" validate={required()} />
                    </Grid>
                    <Grid item xs={10} sm={10} md={6}>
                        <TextInput source="country" validate={required()} />
                    </Grid>
                </Grid>
            </SimpleForm>
        </EditContextProvider>
    );
};

interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
}

function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
        </div>
    );
}

function a11yProps(index: number) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

export default Configuration;
