import { useCallback } from 'react';
import { Button, Card, CardMedia, Dialog, Grid } from '@mui/material';
import {
    BooleanField,
    Datagrid,
    Edit,
    List,
    NumberInput,
    Pagination,
    RowClickFunction,
    SimpleForm,
    TextField,
    TextInput,
    useGetOne,
    useInput,
    useNotify,
    useRedirect,
} from 'react-admin';
import { CartItem } from '../types/cart';
import { Item } from '../types/item';

const CartItemEdit = ({ idCart, idCartItem, open }: { idCart?: string; idCartItem?: string; open: boolean }) => {
    const redirect = useRedirect();
    const notify = useNotify();

    const handleClose = () => {
        redirect(`/carts/${idCart}`);
    };

    const onError = () => {
        notify(`Edit failed`, { type: 'error' });
        redirect(`/carts/${idCart}`);
    };

    const onSuccess = () => {
        notify(`Edit successfully`, { type: 'success' });
        redirect(`/carts/${idCart}`);
    };

    return (
        <Dialog open={open} onClose={handleClose} className="test">
            {open && (
                <Edit<CartItem>
                    resource="cartitems"
                    id={idCartItem}
                    sx={{ height: '100%', width: 500, '& .RaCreate-main': { mt: 0 } }}
                    mutationMode="pessimistic"
                    mutationOptions={{ onSuccess, onError }}
                >
                    <CartItemForm />
                </Edit>
            )}
        </Dialog>
    );
};

export const CartItemForm = () => {
    return (
        <SimpleForm>
            <TextInput source="name" fullWidth isRequired />
            <NumberInput source="quantity" fullWidth isRequired />
            <MerchandiseInput />
        </SimpleForm>
    );
};

const TYPE_PRICES = ['base', 'premium', 'supreme'];
const NUMBER_OF_TYPE_PRICES = 3;

const MerchandiseInput = () => {
    const { field: itemField } = useInput({ source: 'item' });
    const { field: priceField } = useInput({ source: 'price' });
    const { data: itemData } = useGetOne<Item>('merchandises', { id: itemField.value || '000000000000' });

    const handleChoosePrice = useCallback(
        (size: string, amount: number) => {
            const value = {
                size,
                amount,
            };
            priceField.onChange(value);
        },
        [priceField],
    );

    const handleRowClick: RowClickFunction = useCallback((id, resource, record) => {
        itemField.onChange(id);
        for (let i = 0; i < NUMBER_OF_TYPE_PRICES; i++)
            if (record?.prices?.[TYPE_PRICES[i]]) {
                handleChoosePrice(TYPE_PRICES[i], +(record?.prices?.[TYPE_PRICES[i]] || 0));
                break;
            }
        return '#';
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const gridImage = (
        <Grid item xs={8} sx={{ height: '200px' }}>
            <Card sx={{ height: '100%' }}>
                <CardMedia
                    component="img"
                    image={`${itemData?.itemImages?.[0]}`}
                    sx={{ width: '100%', height: '100%', objectFit: 'contain' }}
                />
            </Card>
        </Grid>
    );

    const gridTypePrice = (
        <Grid container item xs={4} rowSpacing={2}>
            {TYPE_PRICES.map((type: string) => {
                return itemData?.prices?.[type] ? (
                    <Grid item xs={12} key={type}>
                        <Button
                            variant={type === priceField?.value?.size ? 'contained' : 'outlined'}
                            onClick={() => handleChoosePrice(type, +(itemData?.prices?.[type] || 0))}
                            fullWidth
                        >
                            {`${type} ${itemData?.prices?.[type]}`}
                        </Button>
                    </Grid>
                ) : (
                    <div key={type}></div>
                );
            })}
        </Grid>
    );

    return (
        <>
            <Grid container mb={3} spacing={2} sx={[itemData?.itemImages?.[0] ? {} : { display: 'none' }]}>
                {gridImage}
                {gridTypePrice}
            </Grid>

            <List
                resource="merchandises"
                actions={false}
                pagination={<Pagination rowsPerPageOptions={[10]} />}
                disableSyncWithLocation
                sx={{ width: '100%' }}
            >
                <Datagrid bulkActionButtons={false} rowClick={handleRowClick}>
                    <TextField source="name" />
                    <BooleanField source="active" />
                </Datagrid>
            </List>
        </>
    );
};

export default CartItemEdit;
