import { Edit, SimpleForm } from 'react-admin';
import ItemsList from './ItemsList';

const OccasionEdit = () => {
    return (
        <Edit>
            <SimpleForm>
                <ItemsList />
            </SimpleForm>
        </Edit>
    );
};

export default OccasionEdit;
