import { useCallback } from 'react';
import { Button, Card, CardMedia, Dialog, Grid } from '@mui/material';
import {
    BooleanField,
    Create,
    Datagrid,
    List,
    Pagination,
    RowClickFunction,
    TextField,
    useGetOne,
    useInput,
    useNotify,
    useRedirect,
} from 'react-admin';
import { CartItem } from '../types/cart';
import { Item } from '../types/item';
import { CartItemForm } from './CartItemEdit';

const CartItemCreate = ({ idCart, open }: { idCart?: string; open: boolean }) => {
    const redirect = useRedirect();
    const notify = useNotify();

    const handleClose = () => {
        redirect(`/carts/${idCart}`);
    };

    const onError = () => {
        notify(`Create failed`, { type: 'error' });
        redirect(`/carts/${idCart}`);
    };

    const onSuccess = () => {
        notify(`Create successfully`, { type: 'success' });
        redirect(`/carts/${idCart}`);
    };

    return (
        <Dialog open={open} onClose={handleClose}>
            {open && (
                <Create<CartItem>
                    resource="cartitems"
                    sx={{ width: 500, '& .RaCreate-main': { mt: 0 } }}
                    mutationOptions={{ onSuccess, onError }}
                >
                    <CartItemForm />
                </Create>
            )}
        </Dialog>
    );
};

const TYPE_PRICES = ['base', 'premium', 'supreme'];
const NUMBER_OF_TYPE_PRICES = 3;

const MerchandiseInput = () => {
    const { field: itemField } = useInput({ source: 'item' });
    const { field: priceField } = useInput({ source: 'price' });
    const { data: itemData } = useGetOne<Item>('merchandises', { id: itemField.value || '000000000000' });

    const handleChoosePrice = useCallback(
        (size: string, amount: number) => {
            const value = {
                size,
                amount,
            };
            priceField.onChange(value);
        },
        [priceField],
    );

    const handleRowClick: RowClickFunction = useCallback((id, resource, record) => {
        itemField.onChange(id);
        for (let i = 0; i < NUMBER_OF_TYPE_PRICES; i++)
            if (record?.prices?.[TYPE_PRICES[i]]) {
                handleChoosePrice(TYPE_PRICES[i], +(record?.prices?.[TYPE_PRICES[i]] || 0));
                break;
            }
        return '#';
    }, []);

    const gridImage = (
        <Grid item xs={8} sx={{ height: '200px' }}>
            <Card sx={{ height: '100%' }}>
                <CardMedia
                    component="img"
                    image={`${itemData?.itemImages?.[0]}`}
                    sx={{ width: '100%', height: '100%', objectFit: 'contain' }}
                />
            </Card>
        </Grid>
    );

    const gridTypePrice = (
        <Grid container item xs={4} rowSpacing={2}>
            {TYPE_PRICES.map((type: string) => (
                <Grid item xs={12}>
                    <Button
                        variant={type === priceField?.value?.size ? 'contained' : 'outlined'}
                        onClick={() => handleChoosePrice(type, +(itemData?.prices?.[type] || 0))}
                        fullWidth
                    >
                        {`${type} ${itemData?.prices?.[type]}`}
                    </Button>
                </Grid>
            ))}
        </Grid>
    );

    return (
        <>
            <Grid container mb={3} spacing={2} display={itemData?.itemImages?.[0] ? 'flex' : 'none'}>
                {gridImage}
                {gridTypePrice}
            </Grid>

            <List
                resource="merchandises"
                actions={false}
                pagination={<Pagination rowsPerPageOptions={[10]} />}
                disableSyncWithLocation
                sx={{ width: '100%' }}
            >
                <Datagrid bulkActionButtons={false} rowClick={handleRowClick}>
                    <TextField source="name" />
                    <BooleanField source="active" />
                </Datagrid>
            </List>
        </>
    );
};

export default CartItemCreate;
