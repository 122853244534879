import {
    EditContextProvider,
    Loading,
    NumberInput,
    ReferenceArrayInput,
    SimpleForm,
    useEditController,
    useGetList,
    useNotify,
    useRefresh,
} from 'react-admin';
import ToolbarCustom from '../components/ToolbarCustom';
import DeliveryZone from './DeliveryZone';

const Bill = () => {
    const { data, isLoading } = useGetList('configuration', { sort: { field: 'createdAt', order: '-1' } });
    if (isLoading) return <Loading />;
    return <BillForm id={data?.[0]?.id} />;
};

const BillForm = ({ id }: { id: string }) => {
    const notify = useNotify();
    const refresh = useRefresh();

    const onSuccess = () => {
        notify(`Update Successfully`, { type: 'success' });
        refresh();
    };

    const onError = () => {
        notify(`Update Failed`, { type: 'error' });
        refresh();
    };

    const billController = useEditController({
        resource: 'configuration',
        redirect: '/bills',
        id,
        mutationMode: 'pessimistic',
        mutationOptions: { onSuccess, onError },
    });
    if (billController.isLoading) return <Loading />;
    return (
        <EditContextProvider value={billController}>
            <SimpleForm toolbar={<ToolbarCustom noDelete />}>
                <NumberInput source="tax" />
                <NumberInput source="shipping" />
                <ReferenceArrayInput source="deliveryZones" reference="deliveryzone">
                    <>
                        <DeliveryZone />
                    </>
                </ReferenceArrayInput>
            </SimpleForm>
        </EditContextProvider>
    );
};

export default Bill;
