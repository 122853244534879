import ReviewsIcon from '@mui/icons-material/Reviews';

import ReviewsList from './ReviewsList';
// import ReviewsCreate from './ReviewsCreate';

const reviews = {
    list: ReviewsList,
    // edit: ReviewsEdit,
    // create: ReviewsCreate,
    icon: ReviewsIcon,
};

export default reviews;
