import {
    DateInput,
    Edit,
    Form,
    FunctionField,
    Labeled,
    NumberField,
    ReferenceField,
    required,
    SelectInput,
    TextField,
    TextInput,
} from 'react-admin';
import { Box, Grid, Card, CardContent } from '@mui/material';
import OrderPayment from './OrderPayment';
import ToolbarCustom from '../components/ToolbarCustom';
import EditDialogButton from '../components/EditDialogButton';

const Spacer = () => <Box m={1}>&nbsp;</Box>;

const SelectStatus = () => {
    return (
        <Grid item xs={12} sm={12} md={6}>
            <SelectInput
                source="status"
                choices={[
                    { id: 'pending', name: 'pending' },
                    { id: 'confirmed', name: 'confirmed' },
                    { id: 'processing', name: 'processing' },
                    { id: 'completed', name: 'completed' },
                    { id: 'cancelled', name: 'cancelled' },
                ]}
                validate={required()}
            />
        </Grid>
    );
};
const Date = () => {
    return (
        <Grid item xs={12} sm={12} md={6}>
            <DateInput source="deliveryDate" />
        </Grid>
    );
};
const RecipientName = () => {
    return (
        <Grid item xs={12} sm={12} md={6}>
            <Labeled>
                <ReferenceField label="Recipient Name" source="recipient" reference="recipients">
                    <TextField source="name" />
                </ReferenceField>
            </Labeled>
        </Grid>
    );
};
const Total = () => {
    return (
        <Grid item xs={12} sm={12} md={6}>
            <Labeled>
                <ReferenceField label="Total" source="cart" reference="carts">
                    <NumberField source="total" options={{ style: 'currency', currency: 'USD' }} />
                </ReferenceField>
            </Labeled>
        </Grid>
    );
};
const CustomerInformation = () => {
    return (
        <Grid item xs={12} sm={12} md={4}>
            <Labeled>
                <ReferenceField label="Customer name" source="user" reference="users">
                    <TextField source="name" />
                </ReferenceField>
            </Labeled>
            <Spacer />
            <Labeled>
                {/* <DialogUpdateField source="locations"> */}
                <ReferenceField label="Delivery Address" source="recipient" reference="recipients">
                    <ReferenceField source="location" reference="locations">
                        <FunctionField
                            render={(record: any) => {
                                return (
                                    <>
                                        {`${record.address1}, ${record.city}, ${record.zipcode}`}
                                        <EditDialogButton id={record.id} resource="locations">
                                            <TextInput source="address1" fullWidth />
                                            <TextInput source="city" fullWidth />
                                            <TextInput source="zipcode" fullWidth />
                                        </EditDialogButton>
                                    </>
                                );
                            }}
                        />
                    </ReferenceField>
                </ReferenceField>
                {/* </DialogUpdateField> */}
            </Labeled>
        </Grid>
    );
};

const OrderEdit = () => {
    return (
        <Edit component="div">
            <Form>
                <Box maxWidth="50em">
                    <Card>
                        <CardContent>
                            <Grid container spacing={1}>
                                <Grid item xs={12} sm={12} md={8}>
                                    <Grid container>
                                        <Date />
                                        <RecipientName />
                                    </Grid>
                                    <Grid container>
                                        <SelectStatus />
                                        <Total />
                                    </Grid>
                                </Grid>

                                <CustomerInformation />
                            </Grid>
                            <Spacer />
                            <TextInput source="message" multiline rows={5} fullWidth resettable />
                            <TextInput source="specialInstruction" multiline rows={5} fullWidth resettable />
                            <OrderPayment />
                        </CardContent>
                        <ToolbarCustom noDelete />
                    </Card>
                </Box>
            </Form>
        </Edit>
    );
};

export default OrderEdit;
