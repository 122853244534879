// in src/authProvider.ts

import { AuthProvider } from 'react-admin';

const URL_BACKEND = (process.env.REACT_APP_ADMIN_API_URL || "http://localhost:3003/admin_api") + "/accounts";

const authProvider: AuthProvider = {
    login: async ({ username, password }) => {
        try {
            const request = new Request(`${URL_BACKEND}/login`, {
                method: 'POST',
                body: JSON.stringify({ username, password }),
                headers: new Headers({ 'Content-Type': 'application/json' }),
            });

            const response = await fetch(request);
            const logData = await response.json();

            if (response.status < 200 || response.status >= 300) return Promise.reject(logData.message);

            localStorage.setItem('token', logData.token);
            localStorage.setItem('user', JSON.stringify(logData.data));
            return Promise.resolve();
        } catch (error) {
            return Promise.reject('Network error');
        }
    },
    logout: () => {
        localStorage.removeItem('token');
        localStorage.removeItem('user');
        return Promise.resolve();
    },
    checkError: ({ status }) => {
        if (status === 401 || status === 403) {
            localStorage.removeItem('token');
            return Promise.reject();
        }
        return Promise.resolve();
    },
    checkAuth: async () => {
        try {
            const token = localStorage.getItem('token');
            const request = new Request(`${URL_BACKEND}/ping`, {
                method: 'GET',
                headers: new Headers({ authorization: `JWT ${token}` }),
            });

            const response = await fetch(request);

            if (response.status < 200 || response.status >= 300) return Promise.reject();

            Promise.resolve();
        } catch (error) {
            Promise.resolve();
        }
    },
    // called when the user navigates to a new location, to check for permissions / roles
    getPermissions: () => Promise.resolve(),
};

export default authProvider;
