import { Box, Card, CardContent, Grid, InputAdornment } from '@mui/material';
import {
    BooleanInput,
    Create,
    FormTab,
    NumberInput,
    ReferenceArrayInput,
    SelectInput,
    TabbedForm,
    TextInput,
} from 'react-admin';
import CreateDialogButton from '../components/CreateDialogButton';
import BadgeInput from './BadgeInput';
import { Spacer } from './MerchandiseEdit';
import ImageForm from './MerchandiseEditImage';
import SubItemInput from './SubItemInput';

const MerchandiseCreate = () => {
    return (
        <Create redirect="list">
            <Box maxWidth="50em">
                <Card>
                    <TabbedForm>
                        <FormTab label="summary">
                            <CardContent>
                                <Grid container spacing={7}>
                                    <Grid item xs={10} sm={10} md={4}>
                                        <TextInput source="name" />
                                    </Grid>
                                    <Grid item xs={10} sm={10} md={4}>
                                        <BooleanInput source="active" />
                                    </Grid>
                                    <Grid item xs={10} sm={10} md={4}>
                                        <BooleanInput source="isPickupOnly" />
                                    </Grid>
                                </Grid>
                                <Grid container spacing={7}>
                                    <Grid item xs={10} sm={10} md={4}>
                                        <NumberInput
                                            InputProps={{
                                                startAdornment: <InputAdornment position="start">$</InputAdornment>,
                                            }}
                                            source="prices.base"
                                        />
                                    </Grid>
                                    <Grid item xs={10} sm={10} md={4}>
                                        <NumberInput
                                            InputProps={{
                                                startAdornment: <InputAdornment position="start">$</InputAdornment>,
                                            }}
                                            source="prices.premium"
                                        />
                                    </Grid>
                                    <Grid item xs={10} sm={10} md={4}>
                                        <NumberInput
                                            InputProps={{
                                                startAdornment: <InputAdornment position="start">$</InputAdornment>,
                                            }}
                                            source="prices.supreme"
                                        />
                                    </Grid>
                                </Grid>
                                <Spacer />
                                <TextInput source="description" multiline fullWidth rows={5} />
                            </CardContent>
                        </FormTab>
                        <FormTab label="Image">
                            <ImageForm source="itemImages" />
                        </FormTab>
                        <FormTab label="Sub Items">
                            <CreateDialogButton title="Sub Item" resource="subitem">
                                <TextInput source="name" fullWidth />
                                <SelectInput
                                    source="type"
                                    fullWidth
                                    choices={[
                                        { id: 'flower', name: 'Flower' },
                                        { id: 'vase', name: 'Vase' },
                                        { id: 'color', name: 'Color' },
                                        { id: 'occasion', name: 'Occasion' },
                                        { id: 'category', name: 'Category' },
                                    ]}
                                />
                            </CreateDialogButton>
                            <ReferenceArrayInput source="subMerchandise" reference="subitem">
                                <SubItemInput />
                            </ReferenceArrayInput>
                        </FormTab>
                        <FormTab label="Badges">
                            <CreateDialogButton title="Badge" resource="itembadge">
                                <TextInput source="name" fullWidth />
                                <SelectInput
                                    source="type"
                                    fullWidth
                                    choices={[
                                        { id: 'primary', name: 'Primary' },
                                        { id: 'success', name: 'Success' },
                                        { id: 'danger', name: 'Danger' },
                                    ]}
                                />
                            </CreateDialogButton>
                            <ReferenceArrayInput source="itemBadges" reference="itembadge">
                                <BadgeInput />
                            </ReferenceArrayInput>
                        </FormTab>
                    </TabbedForm>
                </Card>
            </Box>
        </Create>
    );
};

export default MerchandiseCreate;
