import { useState } from 'react';
import { MenuItemLink, MenuProps, useSidebarState } from 'react-admin';
import Box from '@mui/material/Box';
import SubMenu from './SubMenu';

import orders from '../orders';
import users from '../users';
import merchandises from '../merchandises';
import photos from '../photos';
import carts from '../cart';
import events from '../event';
import comments from '../comments';
import MoneyIcon from '@mui/icons-material/Money';
import occasions from '../occasions';
import reviews from '../reviews';

type MenuName = 'menuAccount' | 'menuShop';
const Menu = ({ dense = false }: MenuProps) => {
    const [state, setState] = useState({
        menuAccount: true,
        menuShop: true,
    });
    const [open] = useSidebarState();
    const handleToggle = (menu: MenuName) => {
        setState((state) => ({ ...state, [menu]: !state[menu] }));
    };
    return (
        <Box
            sx={{
                width: open ? 200 : 50,
                marginTop: 1,
                marginBottom: 1,
            }}
        >
            <SubMenu
                handleToggle={() => handleToggle('menuAccount')}
                isOpen={state.menuAccount}
                name="Account"
                icon={<orders.icon />}
                dense={dense}
            >
                <MenuItemLink
                    to="/orders"
                    state={{ _scrollToTop: true }}
                    primaryText={'Orders'}
                    leftIcon={<orders.icon />}
                    dense={dense}
                />
                <MenuItemLink
                    to="/users"
                    state={{ _scrollToTop: true }}
                    primaryText={'Users'}
                    leftIcon={<users.icon />}
                    dense={dense}
                />
            </SubMenu>
            <SubMenu
                handleToggle={() => handleToggle('menuShop')}
                isOpen={state.menuShop}
                name="Shop"
                icon={<merchandises.icon fontSize="small" />}
                dense={dense}
            >
                <MenuItemLink
                    to="/merchandises"
                    state={{ _scrollToTop: true }}
                    primaryText={'Merchandises'}
                    leftIcon={<merchandises.icon fontSize="small" />}
                    dense={dense}
                />
                <MenuItemLink
                    to="/photos"
                    state={{ _scrollToTop: true }}
                    primaryText={'Photos'}
                    leftIcon={<photos.icon fontSize="small" />}
                    dense={dense}
                />

                <MenuItemLink
                    to="/carts"
                    state={{ _scrollToTop: true }}
                    primaryText={'Prepare Carts'}
                    leftIcon={<carts.icon fontSize="small" />}
                    dense={dense}
                />
                <MenuItemLink
                    to="/events"
                    state={{ _scrollToTop: true }}
                    primaryText={'Events'}
                    leftIcon={<events.icon fontSize="small" />}
                    dense={dense}
                />
                <MenuItemLink
                    to="/comments"
                    state={{ _scrollToTop: true }}
                    primaryText={'Comments'}
                    leftIcon={<comments.icon fontSize="small" />}
                    dense={dense}
                />
                <MenuItemLink
                    to="/occasions"
                    state={{ _scrollToTop: true }}
                    primaryText={'Occasions'}
                    leftIcon={<occasions.icon fontSize="small" />}
                    dense={dense}
                />
                <MenuItemLink
                    to="/reviews"
                    state={{ _scrollToTop: true }}
                    primaryText={'Reviews'}
                    leftIcon={<reviews.icon fontSize="small" />}
                    dense={dense}
                />
            </SubMenu>
            <MenuItemLink
                to="/bill"
                state={{ _scrollToTop: true }}
                primaryText={'Bill'}
                leftIcon={<MoneyIcon fontSize="small" />}
                dense={dense}
            />
        </Box>
    );
};

export default Menu;
