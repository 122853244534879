import OccasionEdit from './OccasionEdit';
import OccasionList from './OccasionList';
import OccaisonCreate from './OccasionsCreate';
import CakeIcon from '@mui/icons-material/Cake';

const occasions = {
    list: OccasionList,
    create: OccaisonCreate,
    edit: OccasionEdit,
    icon: CakeIcon,
};
export default occasions;
