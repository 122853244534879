import { Box, ImageList, ImageListItem, useMediaQuery, useTheme } from '@mui/material';
import { List, useListContext, useCreatePath, TopToolbar, CreateButton } from 'react-admin';
import { Link } from 'react-router-dom';
const GridList = () => {
    const { isLoading } = useListContext();
    return isLoading ? <LoadingGridList /> : <LoadedGridList />;
};

const useColsForWidth = () => {
    const theme = useTheme();
    const sm = useMediaQuery(theme.breakpoints.up('sm'));
    const md = useMediaQuery(theme.breakpoints.up('md'));
    const lg = useMediaQuery(theme.breakpoints.up('lg'));
    const xl = useMediaQuery(theme.breakpoints.up('xl'));
    // there are all dividers of 24, to have full rows on each page
    if (xl) return 9;
    if (lg) return 8;
    if (md) return 6;
    if (sm) return 5;
    // xs={12} // 1
    // sm={6} // 2
    // md={4} // 3
    // lg={3} // 4
    // xl={2} // 6
    // // BCNN: 12
    return 2;
};

const times = (nbChildren: number, fn: (key: number) => any) => Array.from({ length: nbChildren }, (_, key) => fn(key));

const LoadingGridList = () => {
    const { perPage } = useListContext();
    const cols = useColsForWidth();
    return (
        <ImageList rowHeight={180} cols={cols} sx={{ m: 0 }}>
            {times(perPage, (key) => (
                <ImageListItem key={key}>
                    <Box bgcolor="grey.300" height="100%" />
                </ImageListItem>
            ))}
        </ImageList>
    );
};

const LoadedGridList = () => {
    const { data } = useListContext();
    const cols = useColsForWidth();
    const createPath = useCreatePath();
    if (!data) return null;
    return (
        <ImageList rowHeight={180} variant="woven" cols={cols} sx={{ height: 'auto' }} gap={10}>
            {data.map((record) => (
                <Box
                    key={record._id}
                    width="100%"
                    height="100%"
                    sx={{
                        border: '1px solid transparent',
                        padding: '5px',
                        cursor: 'pointer',
                        transition: 'all 0.05s linear 0s',
                        '&:hover': {
                            // border: '1px solid #ccc',
                            // backgroundColor: 'gray',
                            padding: '0px 5px 10px 5px',
                        },
                    }}
                >
                    <ImageListItem
                        component={Link}
                        to={createPath({
                            resource: 'photos',
                            id: record._id,
                            type: 'edit',
                        })}
                    >
                        <img
                            src={`${record.url}?w=164&h=164&fit=crop&auto=format`}
                            srcSet={`${record.url}?w=164&h=164&fit=crop&auto=format&dpr=2 2x`}
                            alt=""
                            loading="lazy"
                            style={{
                                borderRadius: '5px',
                            }}
                        />
                    </ImageListItem>
                </Box>
            ))}
        </ImageList>
    );
};

export const PhotoList = () => {
    return (
        <List actions={<ListActions />}>
            <GridList />
        </List>
    );
};

const ListActions = () => (
    <TopToolbar>
        <CreateButton />
    </TopToolbar>
);

export default PhotoList;
