import { FunctionField, Labeled, ReferenceField, TextField } from 'react-admin';
import { Box, Grid, Typography } from '@mui/material';

const BillingDetails = () => {
    return (
        <Grid item xs={12} sm={12} md={6}>
            <Labeled>
                <ReferenceField label="Billing details" source="paymentId" reference="payments">
                    <FunctionField
                        render={(record: any) => {
                            const { billing_details } = record;

                            const { name, address } = billing_details;
                            const { line1, city, postal_code, state } = address;

                            return (
                                <Box>
                                    <p>{name}</p>
                                    <p>{line1}</p>
                                    <p>{`${city}, ${postal_code}, ${state}`}</p>
                                </Box>
                            );
                        }}
                    />
                </ReferenceField>
            </Labeled>
        </Grid>
    );
};
const Card = () => {
    return (
        <Grid item xs={12} sm={12} md={6}>
            <Labeled>
                <ReferenceField label="Card" source="paymentId" reference="payments">
                    <>
                        <Grid item xs={12} sm={12} md={6}>
                            <TextField source="card.name" />
                        </Grid>
                        <Grid item xs={12} sm={12} md={6}>
                            <Typography>
                                {'...'}
                                {<TextField source="card.last4" />}
                            </Typography>
                        </Grid>
                        <Grid item xs={12} sm={12} md={6}>
                            <Typography>
                                {<TextField source="card.exp_month" />}
                                {'/'}
                                {<TextField source="card.exp_year" />}
                            </Typography>
                        </Grid>
                    </>
                </ReferenceField>
            </Labeled>
        </Grid>
    );
};

const OrderPayment = () => {
    return (
        <>
            <Typography variant="h6">
                <p>Payment</p>
            </Typography>
            <Grid container>
                <BillingDetails />
                <Card />
            </Grid>
        </>
    );
};

export default OrderPayment;
