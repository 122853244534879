import { useCallback } from 'react';
import { Box, Grid, LinearProgress, Typography } from '@mui/material';
import { required, TextInput, useChoicesContext, useInput } from 'react-admin';
import EditDialogButton from '../components/EditDialogButton';
import ZipCodeInput from './Zipcodes';
import CreateDialogButton from '../components/CreateDialogButton';

const DeliveryZone = () => {
    const { allChoices, isLoading, source } = useChoicesContext();
    const { field } = useInput({ source });

    const handleAvailableClick = useCallback(
        (id: string) => {
            if (field.value.indexOf(id) > -1) {
                const value = field.value.filter((choiceId: string) => choiceId !== id);
                field.onChange(value || []);
            } else {
                const value = [...(field.value || []), id];
                field.onChange(value || []);
            }
        },
        [field],
    );

    if (isLoading) return <LinearProgress />;

    return (
        <Box>
            <Grid container spacing={1}>
                <Grid item xs={12}>
                    Delivery zone
                </Grid>
                {allChoices.map((choice: Choice) => (
                    <Grid item key={choice.name}>
                        <Box
                            sx={[
                                {
                                    alignItems: 'center',
                                    justifyContent: 'center',

                                    fontWeight: 'bold',
                                    fontSize: '0.875em',
                                    backgroundColor: '#6c757d',
                                    color: '#fff',
                                    borderRadius: '5px',
                                    cursor: 'pointer',
                                    px: '0.6em',
                                    display: 'flex',
                                    '&:hover': {
                                        backgroundColor: '#AAAAAA',
                                    },
                                },

                                field.value.indexOf(choice._id) > -1
                                    ? {
                                          backgroundColor: '#007bff',
                                          '&:hover': {
                                              backgroundColor: '#0033CC',
                                          },
                                      }
                                    : {},
                            ]}
                            textAlign="center"
                        >
                            <Typography sx={{ padding: '10px' }} onClick={() => handleAvailableClick(choice._id)}>
                                {`${choice.name} - ${choice.fee}$`}
                            </Typography>

                            <EditDialogButton id={choice._id} resource="deliveryzone">
                                <TextInput source="name" fullWidth />
                                <TextInput source="fee" fullWidth />
                                <ZipCodeInput />
                            </EditDialogButton>
                        </Box>
                    </Grid>
                ))}
            </Grid>
            <CreateDialogButton title="Delivery zone" resource="deliveryzone">
                <TextInput source="name" fullWidth validate={required()} />
                <TextInput source="fee" fullWidth validate={required()} />
                <ZipCodeInput />
            </CreateDialogButton>
        </Box>
    );
};

interface Choice {
    _id: string;
    name: string;
    fee: number;
    zipcodes: string[];
}
export default DeliveryZone;
