import MerchandiseEdit from './MerchandiseEdit';
import MerchandiseList from './MerchandiseList';
import MerchandiseIcon from '@mui/icons-material/Inventory2';
import MerchandiseCreate from './MerchandiseCreate';
const merchandises = {
    list: MerchandiseList,
    edit: MerchandiseEdit,
    create: MerchandiseCreate,
    icon: MerchandiseIcon,
};

export default merchandises;
