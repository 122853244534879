import { Datagrid, DateField, List, TextField } from 'react-admin';

const CartList = () => {
    return (
        <List sort={{ field: 'createdAt', order: '-1' }}>
            <Datagrid bulkActionButtons={false} rowClick="edit">
                <TextField source="name" />
                <TextField source="status" />
                <DateField source="createdAt" />
            </Datagrid>
        </List>
    );
};

export default CartList;
