import { useCallback } from 'react';
import { Box, Drawer } from '@mui/material';
import {
    Datagrid,
    DateField,
    DateInput,
    ExportButton,
    Identifier,
    List,
    ReferenceField,
    SelectInput,
    TextField,
    TopToolbar,
    useListContext,
} from 'react-admin';

import { matchPath, useLocation, useNavigate } from 'react-router-dom';
import CommentEdit from './CommentEdit';
import rowStyle from './rowStyle';

const ListActions = () => {
    const { total, isLoading } = useListContext();
    return (
        <TopToolbar>
            <ExportButton disabled={isLoading || total === 0} />
        </TopToolbar>
    );
};

const commentFilters = [
    <DateInput source="createdAt" alwaysOn />,
    <SelectInput
        source="status"
        choices={[
            { id: 'posted', name: 'posted' },
            { id: 'pending', name: 'pending' },
            { id: 'rejected', name: 'rejected' },
        ]}
        alwaysOn
    />,
];

export interface props {
    selectedRow?: Identifier;
}
const CommentData = ({ selectedRow }: props) => {
    return (
        <Datagrid
            rowStyle={rowStyle(selectedRow)}
            rowClick="edit"
            bulkActionButtons={false}
            optimized
            sx={{
                '& .RaDatagrid-thead': {
                    borderLeftColor: 'transparent',
                    borderLeftWidth: 5,
                    borderLeftStyle: 'solid',
                },
                '& .column-content': {
                    maxWidth: '18em',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    whiteSpace: 'nowrap',
                },
            }}
        >
            <DateField label="Date" source="createdAt" />
            <ReferenceField label="User Name" source="user" reference="users" sortable={false}>
                <TextField source="name" />
            </ReferenceField>
            <ReferenceField label="Event Name" source="event" reference="events" sortable={false}>
                <TextField source="name" />
            </ReferenceField>
            <ReferenceField label="Shop" source="shop" reference="shop" sortable={false}>
                <TextField source="name" />
            </ReferenceField>
            <ReferenceField label="Order" source="order" reference="orders" sortable={false}>
                <TextField source="shortId" />
            </ReferenceField>
            <ReferenceField label="Merchandise" source="item" reference="merchandises" sortable={false}>
                <TextField source="name" />
            </ReferenceField>
            <TextField source="content" sortable={false} />
            <TextField source="status" sortable={false} />
        </Datagrid>
    );
};

const CommentList = () => {
    const location = useLocation();
    const navigate = useNavigate();

    const handleClose = useCallback(() => {
        navigate('/comments');
    }, [navigate]);

    const match = matchPath('/comments/:id', location.pathname);

    return (
        <Box display="flex">
            <List
                filters={commentFilters}
                actions={<ListActions />}
                sx={{
                    flexGrow: 1,
                    marginRight: !!match ? '400px' : 0,
                }}
                perPage={12}
                sort={{ field: 'createdAt', order: '-1' }}
            >
                <CommentData selectedRow={!!match ? parseInt((match as any).params.id, 10) : undefined} />
            </List>
            <Drawer variant="persistent" open={!!match} anchor="right" onClose={handleClose} sx={{ zIndex: 100 }}>
                {!!match && <CommentEdit id={(match as any).params.id} onCancel={handleClose} />}
            </Drawer>
        </Box>
    );
};

export default CommentList;
