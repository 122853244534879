export interface TimeZone {
    name: string;
    id: string;
}

const TIMEZONES: TimeZone[] = [
    { name: '(GMT-12:00) International Date Line West', id: 'Etc/GMT+12' },
    { name: '(GMT-11:00) Midway Island, Samoa', id: 'Pacific/Midway' },
    { name: '(GMT-10:00) Hawaii', id: 'Pacific/Honolulu' },
    { name: '(GMT-09:00) Alaska', id: 'US/Alaska' },
    { name: '(GMT-08:00) Pacific Time (US & Canada)', id: 'America/Los_Angeles' },
    { name: '(GMT-08:00) Tijuana, Baja California', id: 'America/Tijuana' },
    { name: '(GMT-07:00) Arizona', id: 'US/Arizona' },
    { name: '(GMT-07:00) Chihuahua, La Paz, Mazatlan', id: 'America/Chihuahua' },
    { name: '(GMT-07:00) Mountain Time (US & Canada)', id: 'US/Mountain' },
    { name: '(GMT-06:00) Central America', id: 'America/Managua' },
    { name: '(GMT-06:00) Central Time (US & Canada)', id: 'US/Central' },
    { name: '(GMT-06:00) Guadalajara, Mexico City, Monterrey', id: 'America/Mexico_City' },
    { name: '(GMT-06:00) Saskatchewan', id: 'Canada/Saskatchewan' },
    { name: '(GMT-05:00) Bogota, Lima, Quito, Rio Branco', id: 'America/Bogota' },
    { name: '(GMT-05:00) Eastern Time (US & Canada)', id: 'US/Eastern' },
    { name: '(GMT-05:00) Indiana (East)', id: 'US/East-Indiana' },
    { name: '(GMT-04:00) Atlantic Time (Canada)', id: 'Canada/Atlantic' },
    { name: '(GMT-04:00) Caracas, La Paz', id: 'America/Caracas' },
    { name: '(GMT-04:00) Manaus', id: 'America/Manaus' },
    { name: '(GMT-04:00) Santiago', id: 'America/Santiago' },
    { name: '(GMT-03:30) Newfoundland', id: 'Canada/Newfoundland' },
    { name: '(GMT-03:00) Brasilia', id: 'America/Sao_Paulo' },
    { name: '(GMT-03:00) Buenos Aires, Georgetown', id: 'America/Argentina/Buenos_Aires' },
    { name: '(GMT-03:00) Greenland', id: 'America/Godthab' },
    { name: '(GMT-03:00) Montevideo', id: 'America/Montevideo' },
    { name: '(GMT-02:00) Mid-Atlantic', id: 'America/Noronha' },
    { name: '(GMT-01:00) Cape Verde Is.', id: 'Atlantic/Cape_Verde' },
    { name: '(GMT-01:00) Azores', id: 'Atlantic/Azores' },
    { name: '(GMT+00:00) Casablanca, Monrovia, Reykjavik', id: 'Africa/Casablanca' },
    { name: '(GMT+00:00) Greenwich Mean Time : Dublin, Edinburgh, Lisbon, London', id: 'Etc/Greenwich' },
    { name: '(GMT+01:00) Amsterdam, Berlin, Bern, Rome, Stockholm, Vienna', id: 'Europe/Amsterdam' },
    { name: '(GMT+01:00) Belgrade, Bratislava, Budapest, Ljubljana, Prague', id: 'Europe/Belgrade' },
    { name: '(GMT+01:00) Brussels, Copenhagen, Madrid, Paris', id: 'Europe/Brussels' },
    { name: '(GMT+01:00) Sarajevo, Skopje, Warsaw, Zagreb', id: 'Europe/Sarajevo' },
    { name: '(GMT+01:00) West Central Africa', id: 'Africa/Lagos' },
    { name: '(GMT+02:00) Amman', id: 'Asia/Amman' },
    { name: '(GMT+02:00) Athens, Bucharest, Istanbul', id: 'Europe/Athens' },
    { name: '(GMT+02:00) Beirut', id: 'Asia/Beirut' },
    { name: '(GMT+02:00) Cairo', id: 'Africa/Cairo' },
    { name: '(GMT+02:00) Harare, Pretoria', id: 'Africa/Harare' },
    { name: '(GMT+02:00) Helsinki, Kyiv, Riga, Sofia, Tallinn, Vilnius', id: 'Europe/Helsinki' },
    { name: '(GMT+02:00) Jerusalem', id: 'Asia/Jerusalem' },
    { name: '(GMT+02:00) Minsk', id: 'Europe/Minsk' },
    { name: '(GMT+02:00) Windhoek', id: 'Africa/Windhoek' },
    { name: '(GMT+03:00) Kuwait, Riyadh, Baghdad', id: 'Asia/Kuwait' },
    { name: '(GMT+03:00) Moscow, St. Petersburg, Volgograd', id: 'Europe/Moscow' },
    { name: '(GMT+03:00) Nairobi', id: 'Africa/Nairobi' },
    { name: '(GMT+03:00) Tbilisi', id: 'Asia/Tbilisi' },
    { name: '(GMT+03:30) Tehran', id: 'Asia/Tehran' },
    { name: '(GMT+04:00) Abu Dhabi, Muscat', id: 'Asia/Muscat' },
    { name: '(GMT+04:00) Baku', id: 'Asia/Baku' },
    { name: '(GMT+04:00) Yerevan', id: 'Asia/Yerevan' },
    { name: '(GMT+04:30) Kabul', id: 'Asia/Kabul' },
    { name: '(GMT+05:00) Yekaterinburg', id: 'Asia/Yekaterinburg' },
    { name: '(GMT+05:00) Islamabad, Karachi, Tashkent', id: 'Asia/Karachi' },
    { name: '(GMT+05:30) Sri Jayawardenapura', id: 'Asia/Calcutta' },
    { name: '(GMT+05:45) Kathmandu', id: 'Asia/Katmandu' },
    { name: '(GMT+06:00) Almaty, Novosibirsk', id: 'Asia/Almaty' },
    { name: '(GMT+06:00) Astana, Dhaka', id: 'Asia/Dhaka' },
    { name: '(GMT+06:30) Yangon (Rangoon)', id: 'Asia/Rangoon' },
    { name: '(GMT+07:00) Bangkok, Hanoi, Jakarta', id: 'Asia/Bangkok' },
    { name: '(GMT+07:00) Krasnoyarsk', id: 'Asia/Krasnoyarsk' },
    { name: '(GMT+08:00) Beijing, Chongqing, Hong Kong, Urumqi', id: 'Asia/Hong_Kong' },
    { name: '(GMT+08:00) Kuala Lumpur, Singapore', id: 'Asia/Kuala_Lumpur' },
    { name: '(GMT+08:00) Irkutsk, Ulaan Bataar', id: 'Asia/Irkutsk' },
    { name: '(GMT+08:00) Perth', id: 'Australia/Perth' },
    { name: '(GMT+08:00) Taipei', id: 'Asia/Taipei' },
    { name: '(GMT+09:00) Osaka, Sapporo, Tokyo', id: 'Asia/Tokyo' },
    { name: '(GMT+09:00) Seoul', id: 'Asia/Seoul' },
    { name: '(GMT+09:00) Yakutsk', id: 'Asia/Yakutsk' },
    { name: '(GMT+09:30) Adelaide', id: 'Australia/Adelaide' },
    { name: '(GMT+09:30) Darwin', id: 'Australia/Darwin' },
    { name: '(GMT+10:00) Brisbane', id: 'Australia/Brisbane' },
    { name: '(GMT+10:00) Canberra, Melbourne, Sydney', id: 'Australia/Canberra' },
    { name: '(GMT+10:00) Hobart', id: 'Australia/Hobart' },
    { name: '(GMT+10:00) Guam, Port Moresby', id: 'Pacific/Guam' },
    { name: '(GMT+10:00) Vladivostok', id: 'Asia/Vladivostok' },
    { name: '(GMT+11:00) Magadan, Solomon Is., New Caledonia', id: 'Asia/Magadan' },
    { name: '(GMT+12:00) Auckland, Wellington', id: 'Pacific/Auckland' },
    { name: '(GMT+12:00) Fiji, Kamchatka, Marshall Is.', id: 'Pacific/Fiji' },
    { name: "(GMT+13:00) Nuku'alofa", id: 'Pacific/Tongatapu' },
];

export default TIMEZONES;
