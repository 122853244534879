import CartList from './CartList';
import CartEdit from './CartEdit';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import CartCreate from './CartCreate';
const carts = {
    list: CartList,
    edit: CartEdit,
    create: CartCreate,
    icon: ShoppingCartIcon,
};

export default carts;
