import { Typography } from '@mui/material';
import { useCallback } from 'react';
import {
    Create,
    Datagrid,
    DateField,
    FunctionField,
    List,
    Pagination,
    required,
    RowClickFunction,
    SelectInput,
    SimpleForm,
    TextField,
    TextInput,
    useGetOne,
    useInput,
} from 'react-admin';

const EventCreate = () => {
    return (
        <Create redirect="list">
            <SimpleForm sx={{ maxWidth: '26em' }}>
                <TextInput
                    source="name"
                    fullWidth
                    isRequired
                    validate={(value) => {
                        if (!value) return { message: 'Name cannot is empty string', args: {} };
                    }}
                />
                <SelectInput
                    fullWidth
                    source="status"
                    choices={[
                        { id: 'pending', name: 'pending' },
                        { id: 'published', name: 'published' },
                        { id: 'inReview', name: 'In review' },
                        { id: 'rejected', name: 'Rejected' },
                    ]}
                    validate={required()}
                />
                <CartInput />
            </SimpleForm>
        </Create>
    );
};

const CartInput = () => {
    const { field: cartField } = useInput({ source: 'cart' });
    const { data: cartData } = useGetOne('carts', { id: cartField.value || '000000000000' });

    const handleRowClick: RowClickFunction = useCallback((id, resource, record) => {
        cartField.onChange(id);
        return '#';
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
            <Typography variant="h6" mb={2}>
                Total: {cartData?.total}
            </Typography>

            <List
                actions={false}
                resource="carts"
                pagination={<Pagination rowsPerPageOptions={[10]} />}
                disableSyncWithLocation
            >
                <Datagrid bulkActionButtons={false} rowClick={handleRowClick}>
                    <TextField fullWidth source="name" />
                    <TextField fullWidth source="status" />
                    <DateField fullWidth source="createdAt" />
                    <FunctionField
                        fullWidth
                        label="Total cart item"
                        render={(record?: any, _source?: string | undefined) =>
                            record?.items?.length?.toString() || '0'
                        }
                    />
                </Datagrid>
            </List>
        </>
    );
};

export default EventCreate;
