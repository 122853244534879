import { Box, ImageList, ImageListItem } from '@mui/material';
import { useCallback } from 'react';
import { useInput } from 'react-admin';
import ImageInputS3 from '../components/ImageInput';

const ImageForm = ({ source }: { source: string }) => {
    const { field } = useInput({ source });
    const handleClick = useCallback(
        (url: string) => {
            const value = field.value.filter((choiceUrl: string) => choiceUrl !== url);
            field.onChange(value || []);
        },
        [field],
    );

    const handleUploadDone = (url: string) => {
        const value = [...(field.value || []), url];
        console.log(value);
        field.onChange(value || []);
    };

    // console.log(field);
    return (
        <Box width="100%">
            <ImageInputS3 uploadDone={handleUploadDone} />
            <ImageList sx={{ width: '100%', height: 450 }} cols={4} rowHeight={164}>
                {field.value
                    ? field.value.map((url: string) => (
                          <ImageListItem key={url} sx={{ cursor: 'pointer' }}>
                              <img
                                  alt=""
                                  src={`${url}?w=164&h=164&fit=crop&auto=format`}
                                  srcSet={`${url}?w=164&h=164&fit=crop&auto=format&dpr=2 2x`}
                                  onClick={() => handleClick(url)}
                                  loading="lazy"
                              />
                          </ImageListItem>
                      ))
                    : 'No Image'}
            </ImageList>
        </Box>
    );
};

export default ImageForm;
