import { Typography } from '@mui/material';
import Toolbar from '@mui/material/Toolbar';
import { Fragment } from 'react';
import { ToolbarProps, useRecordContext } from 'react-admin';
import ApproveButton from './ApproveButton';
import RejectButton from './RejectButton';
const CommentEditToolbar = (props: ToolbarProps) => {
    const record = useRecordContext(props);
    if (!record) return null;

    return (
        <Toolbar
            sx={{
                backgroundColor: 'background.paper',
                display: 'flex',
                justifyContent: 'space-between',
                minHeight: { sm: 0 },
            }}
        >
            {record.status === 'pending' ? (
                <Fragment>
                    <ApproveButton />
                    <RejectButton />
                </Fragment>
            ) : (
                <Fragment>
                    {record.status === 'rejected' ? (
                        <Typography sx={{ color: 'red', fontSize: '0.9em', fontWeight: 500, fontStyle: 'italic' }}>
                            REJECTED
                        </Typography>
                    ) : (
                        <Typography sx={{ color: 'green', fontSize: '0.9em', fontWeight: 500, fontStyle: 'italic' }}>
                            POSTED
                        </Typography>
                    )}
                </Fragment>
            )}
        </Toolbar>
    );
};

export default CommentEditToolbar;
