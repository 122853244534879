import { Box, Grid, LinearProgress, Typography } from '@mui/material';
import _ from 'lodash';
import { memo, useCallback } from 'react';
import { useChoicesContext, useInput } from 'react-admin';

const SubItemInput = () => {
    const { allChoices, isLoading, source } = useChoicesContext();
    const { field } = useInput({ source });

    const handleClick = useCallback(
        (id: string) => {
            if (field.value.indexOf(id) > -1) {
                const value = field.value.filter((choiceId: string) => choiceId !== id);
                field.onChange(value || []);
            } else {
                const value = [...(field.value || []), id];
                field.onChange(value || []);
            }
        },
        [field],
    );

    if (isLoading) return <LinearProgress />;

    const dataLodash = _.chain(allChoices)
        .groupBy('type')
        .map((value: any, key: string) => ({ type: key, subItem: value }))
        .value();
    return (
        <Box width="100%">
            {dataLodash.map((allChoicesByType: any) => {
                if (allChoicesByType.type !== 'occasion')
                    return (
                        <Grid container spacing={2} key={allChoicesByType.type}>
                            <Grid item xs={12}>
                                <Typography
                                    sx={{
                                        mt: 3,
                                        textTransform: 'capitalize',
                                    }}
                                >
                                    {allChoicesByType.type}
                                </Typography>
                            </Grid>
                            {allChoicesByType.subItem.map((choice: any) => (
                                <Grid item xs={4} key={choice.id}>
                                    <Box
                                        onClick={() => handleClick(choice.id)}
                                        width="100%"
                                        sx={[
                                            {
                                                p: 2,
                                                boxShadow: '0 .125rem .25rem rgba(0,0,0,.075)',
                                                border: '1px solid #ced4da',
                                                borderRadius: '5px',
                                                cursor: 'pointer',
                                                '&:hover': {
                                                    backgroundColor: '#f2f2f2',
                                                },
                                            },
                                            field.value.indexOf(choice.id) > -1
                                                ? {
                                                      backgroundColor: '#7fe998',
                                                      '&:hover': {
                                                          backgroundColor: '#64b978',
                                                      },
                                                  }
                                                : {},
                                        ]}
                                        textAlign="center"
                                    >
                                        <Typography>{choice.name}</Typography>
                                    </Box>
                                </Grid>
                            ))}
                        </Grid>
                    );
                return null;
            })}
        </Box>
    );
};

export default memo(SubItemInput);
