import { Button, CircularProgress, styled } from '@mui/material';
import { MouseEventHandler, useCallback, useState } from 'react';
import { Confirm, RaRecord, SaveButtonProps, setSubmissionErrors, useSaveContext } from 'react-admin';
import { useFormContext, useFormState } from 'react-hook-form';
import ContentSave from '@mui/icons-material/Save';

const defaultIcon = <ContentSave />;

const valueOrDefault = (value: any, defaultValue: any) => (typeof value === 'undefined' ? defaultValue : value);

const StyledButton = styled(Button, {
    name: 'RaSaveButton',
    overridesResolver: (props, styles) => styles.root,
})(({ theme }) => ({
    position: 'relative',
    [`& .MuiSvgIcon-root, & .MuiIcon-root, & .MuiCircularProgress-root`]: {
        marginRight: theme.spacing(1),
    },
    [`& .MuiSvgIcon-root, & .MuiIcon-root`]: {
        fontSize: 18,
    },
}));

const UpdateConfirmButton = <RecordType extends RaRecord = any>(props: SaveButtonProps<RecordType>) => {
    const {
        color = 'primary',
        icon = defaultIcon,
        invalid,
        label = 'Save',
        mutationOptions,
        saving,
        disabled: disabledProp,
        transform,
        variant = 'contained',
        alwaysEnable = false,
        ...rest
    } = props;
    const [open, setOpen] = useState(false);
    const form = useFormContext();
    const saveContext = useSaveContext();
    const { dirtyFields, isValidating, isSubmitting } = useFormState();

    const isDirty = Object.keys(dirtyFields).length > 0;
    const disabled = valueOrDefault(
        alwaysEnable === false || alwaysEnable === undefined ? undefined : !alwaysEnable,
        disabledProp || !isDirty || isValidating || saveContext?.saving || isSubmitting,
    );

    const handleSubmit = useCallback(
        async (values: any) => {
            let errors = null;
            if (saveContext?.save) {
                errors = await saveContext.save(values, {
                    ...mutationOptions,
                    transform,
                });
            }
            if (errors != null) {
                setSubmissionErrors(errors, form.setError);
            }
        },
        [form.setError, saveContext, mutationOptions, transform],
    );

    const handleClick: MouseEventHandler<HTMLButtonElement> = useCallback(
        async (event) => {
            event.stopPropagation();
            await form.handleSubmit(handleSubmit)(event);
            setOpen(false);
        },
        [form, handleSubmit],
    );

    const handleDialogOpen = () => setOpen(true);

    const handleDialogClose = () => setOpen(false);

    const finalSaving = typeof saving !== 'undefined' ? saving : saveContext?.saving || isSubmitting;

    return (
        <>
            <StyledButton
                variant={variant}
                type="button"
                color={color}
                aria-label={label}
                disabled={disabled}
                onClick={handleDialogOpen}
                {...rest}
            >
                {finalSaving ? <CircularProgress size={18} thickness={2} /> : icon}
                {label}
            </StyledButton>
            <Confirm
                isOpen={open}
                loading={isSubmitting}
                title="Warning"
                content="Are you sure you want to update?"
                onConfirm={handleClick}
                onClose={handleDialogClose}
            />
        </>
    );
};

export default UpdateConfirmButton;
