import { Button } from '@mui/material';
import ThumbDown from '@mui/icons-material/ThumbDown';
import { useNotify, useRecordContext, useRedirect, useUpdate } from 'react-admin';
const RejectButton = () => {
    const notify = useNotify();
    const redirectTo = useRedirect();
    const record = useRecordContext();

    const [reject, { isLoading }] = useUpdate(
        'comments',
        { id: record._id, data: { ...record, status: 'rejected' } },
        {
            onSuccess: () => {
                notify('Rejected Success', {
                    type: 'info',
                });
                redirectTo('/comments');
            },
            onError: () => {
                notify('Rejected Error', {
                    type: 'error',
                });
            },
        },
    );

    return record && record.status === 'pending' ? (
        <Button
            variant="outlined"
            color="primary"
            size="small"
            onClick={() => reject()}
            disabled={isLoading}
            startIcon={<ThumbDown sx={{ color: 'red' }} />}
        >
            REJECT
        </Button>
    ) : (
        <span />
    );
};

export default RejectButton;
