import {
    Datagrid,
    DateField,
    DateInput,
    ExportButton,
    Identifier,
    List,
    ReferenceField,
    SelectInput,
    TextField,
    TopToolbar,
    useListContext,
} from 'react-admin';
import { Box, Drawer } from '@mui/material';
import { matchPath, useLocation, useNavigate } from 'react-router-dom';
import rowStyle from '../comments/rowStyle';
import { useCallback } from 'react';
import ReviewsEdit from './ReviewsEdit';

const ListActions = () => {
    const { total, isLoading } = useListContext();
    return (
        <TopToolbar>
            <ExportButton disabled={isLoading || total === 0} />
        </TopToolbar>
    );
};

const reviewsFilter = [
    <DateInput source="updatedAt" alwaysOn />,
    <SelectInput
        source="status"
        choices={[
            { id: 'approved', name: 'approved' },
            { id: 'pending', name: 'pending' },
            { id: 'rejected', name: 'rejected' },
        ]}
        alwaysOn
    />,
];

export interface IProps {
    selectedRow?: Identifier;
}

const ReviewsData = ({ selectedRow }: IProps) => (
    <Datagrid
        rowStyle={rowStyle(selectedRow)}
        rowClick="edit"
        bulkActionButtons={false}
        optimized
        sx={{
            '& .RaDatagrid-thead': {
                borderLeftColor: 'transparent',
                borderLeftWidth: 5,
                borderLeftStyle: 'solid',
            },
            '& .column-content': {
                maxWidth: '18em',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
            },
        }}
    >
        <DateField label="Date" source="updatedAt" sortable />
        <ReferenceField label="User Name" source="user" reference="users" sortable>
            <TextField source="name" />
        </ReferenceField>
        <TextField source="description" sortable={false} />
        <ReferenceField label="Shop" source="shop" reference="shop" sortable>
            <TextField source="name" />
        </ReferenceField>
        <ReferenceField label="Order" source="order" reference="orders" sortable>
            <TextField source="shortId" />
        </ReferenceField>
        <ReferenceField label="Merchandise" source="item" reference="merchandises" sortable>
            <TextField source="name" />
        </ReferenceField>
        <TextField source="rating" sortable />
        <TextField source="status" sortable />
    </Datagrid>
);

const ReviewsList = () => {
    const location = useLocation();
    const navigate = useNavigate();

    const handleClose = useCallback(() => {
        navigate('/reviews');
    }, [navigate]);

    const match = matchPath('/reviews/:id', location.pathname);

    return (
        <Box display="flex">
            <List
                filters={reviewsFilter}
                actions={<ListActions />}
                sx={{
                    flexGrow: 1,
                    marginRight: !!match ? '400px' : 0,
                }}
                perPage={12}
                sort={{ field: 'createdAt', order: '-1' }}
            >
                <ReviewsData selectedRow={!!match ? parseInt((match as any).params.id, 10) : undefined}></ReviewsData>
            </List>
            <Drawer variant="persistent" open={!!match} anchor="right" onClose={handleClose} sx={{ zIndex: 100 }}>
                {!!match && <ReviewsEdit id={(match as any).params.id} onCancel={handleClose} />}
            </Drawer>
        </Box>
    );
};

export default ReviewsList;
