import { useCallback } from 'react';
import { Box, Grid, LinearProgress, Theme, useTheme } from '@mui/material';
import { useChoicesContext, useInput } from 'react-admin';

const BadgeInput = () => {
    const { allChoices, selectedChoices, isLoading, source } = useChoicesContext();
    const { field } = useInput({ source });
    const theme: myTheme = useTheme();

    const handleAvailableClick = useCallback(
        (id: string) => {
            if (field.value.indexOf(id) > -1) {
                const value = field.value.filter((choiceId: string) => choiceId !== id);
                field.onChange(value || []);
            } else {
                const value = [...(field.value || []), id];
                field.onChange(value || []);
            }
        },
        [field],
    );

    if (isLoading) return <LinearProgress />;

    return (
        <Box>
            <Grid container spacing={1} mb={3}>
                <Grid item xs={12}>
                    Selected badges
                </Grid>
                {selectedChoices?.map((choice: Choice) => (
                    <Grid item key={choice.name}>
                        <Box
                            sx={{
                                fontWeight: 'bold',
                                fontSize: '0.875em',
                                backgroundColor: theme.badge[choice.type as keyof Badges].main,
                                color: theme.badge[choice.type as keyof Badges].contrastText,
                                borderRadius: '5px',
                                cursor: 'pointer',
                                px: '10px',
                            }}
                            textAlign="center"
                            onClick={() => handleAvailableClick(choice._id)}
                        >
                            {choice.name}
                        </Box>
                    </Grid>
                ))}
            </Grid>
            <Grid container spacing={1}>
                <Grid item xs={12}>
                    Available badges
                </Grid>
                {allChoices?.map((choice: Choice) => (
                    <Grid item key={choice.name}>
                        <Box
                            sx={{
                                fontWeight: 'bold',
                                fontSize: '0.875em',
                                backgroundColor: theme.badge[choice.type as keyof Badges].main,
                                color: theme.badge[choice.type as keyof Badges].contrastText,
                                borderRadius: '5px',
                                cursor: 'pointer',
                                px: '10px',
                            }}
                            textAlign="center"
                            onClick={() => handleAvailableClick(choice._id)}
                        >
                            {choice.name}
                        </Box>
                    </Grid>
                ))}
            </Grid>
        </Box>
    );
};

interface Choice {
    name: string;
    type: string;
    _id: string;
}

interface BadgeColor {
    main: string;
    contrastText: string;
}

interface Badges {
    primary: BadgeColor;
    success: BadgeColor;
    danger: BadgeColor;
}

interface myTheme extends Theme {
    badge: Badges;
}

export default BadgeInput;
