import { Box, Grid } from '@mui/material';
import { Edit, FormTab, SelectInput, TabbedForm, TextInput } from 'react-admin';
import ToolbarCustom from '../components/ToolbarCustom';
import CartItem from './CartItem';

const CartEdit = () => {
    return (
        <Edit>
            <CartForm />
        </Edit>
    );
};

export const CartForm = () => {
    return (
        <TabbedForm syncWithLocation={false} toolbar={<ToolbarCustom />}>
            <FormTab label="Prepare Cart">
                <Grid container width="100%" spacing={2}>
                    <Grid item xs={12}>
                        <Box display="flex">
                            <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
                                <TextInput source="name" isRequired fullWidth />
                            </Box>
                            <Box flex={1} ml={{ xs: 0, sm: '0.5em' }}>
                                <SelectInput
                                    source="status"
                                    choices={[
                                        { id: 'pending', name: 'Pending' },
                                        { id: 'inReview', name: 'In review' },
                                        { id: 'published', name: 'Published' },
                                        { id: 'rejected', name: 'Rejected' },
                                    ]}
                                    defaultValue="pending"
                                    isRequired
                                    fullWidth
                                />
                            </Box>
                        </Box>
                    </Grid>
                </Grid>
            </FormTab>

            <FormTab label="Items">
                <CartItem />
            </FormTab>
        </TabbedForm>
    );
};

export default CartEdit;
