import EventList from './EventList';
import EventEdit from './EventEdit';
import EventIcon from '@mui/icons-material/Event';
import EventCreate from './EventCreate';
const events = {
    list: EventList,
    edit: EventEdit,
    create: EventCreate,
    icon: EventIcon,
};

export default events;
