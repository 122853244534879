import { Datagrid, FunctionField, List, TextField } from 'react-admin';

const OccasionList = () => {
    return (
        <List>
            <Datagrid rowClick="edit">
                <TextField source="_id" />
                <TextField source="name" />
                <FunctionField label="Total Items" render={(record: any) => `${record.itemsList.length}`} />
            </Datagrid>
        </List>
    );
};

export default OccasionList;
