import { Box, Grid, IconButton, Stack, Typography } from '@mui/material';
import { EditBase, Labeled, ReferenceField, SimpleForm, DateField, TextField, TextInput } from 'react-admin';
import CloseIcon from '@mui/icons-material/Close';
import ReviewsEditToolbar from './ReviewsEditToolbar';

interface Props {
    onCancel: () => void;
    id: string;
}

const ReviewsEdit = ({ ...props }: Props) => {
    const { id, onCancel } = props;

    return (
        <EditBase id={id}>
            <Box pt={5} width={{ xs: '100vW', sm: 400 }} mt={{ xs: 2, sm: 1 }}>
                <Stack direction="row" p={2}>
                    <Typography variant="h6" flex="1">
                        Reviews Detail
                    </Typography>
                    <IconButton onClick={onCancel} size="small">
                        <CloseIcon />
                    </IconButton>
                </Stack>
                <SimpleForm sx={{ pt: 0, pb: 0 }} toolbar={<ReviewsEditToolbar />}>
                    <Grid container rowSpacing={1} mb={1}>
                        <Grid item xs={6}>
                            <Labeled>
                                <ReferenceField label="User Name" source="user" reference="users">
                                    <TextField source="name" />
                                </ReferenceField>
                            </Labeled>
                        </Grid>
                        <Grid item xs={6}>
                            <Labeled>
                                <DateField label="Date" source="updatedAt" />
                            </Labeled>
                        </Grid>
                    </Grid>
                    <Grid item xs={6} mb={4}>
                        <Labeled>
                            <ReferenceField label="Shop Name" source="shop" reference="shop">
                                <TextField source="name" />
                            </ReferenceField>
                        </Labeled>
                        <Labeled>
                            <ReferenceField label="Order ShortId" source="order" reference="orders">
                                <TextField source="shortId" />
                            </ReferenceField>
                        </Labeled>
                        <Labeled>
                            <ReferenceField label="Merchandise Name" source="item" reference="merchandises">
                                <TextField source="name" />
                            </ReferenceField>
                        </Labeled>
                    </Grid>
                    <TextInput
                        source="description"
                        fullWidth
                        multiline
                        rows={5}
                        InputProps={{
                            readOnly: true,
                        }}
                    />
                </SimpleForm>
            </Box>
        </EditBase>
    );
};

export default ReviewsEdit;
