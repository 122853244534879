import { Admin, fetchUtils, Options, Resource, CustomRoutes } from 'react-admin';
import { Route } from 'react-router';

import './App.css';

import orders from './orders';
import users from './users';
import restProvider from './dataProvider';
import authProvider from './authProvider';
import merchandises from './merchandises';
import { BackOfficeTheme } from './layout/themes';
import photos from './photos';
import Configuration from './configuration/Configuration';
import Bill from './bill/Bill';
import carts from './cart';
import events from './event';
import Layouts from './layout/Layout';
import comments from './comments';
import occasions from './occasions';
import reviews from './reviews';

const httpClient = (url: string, options = {} as Options) => {
    const token = localStorage.getItem('token');

    options.headers = new Headers({ Accept: 'application/json' });
    options.headers.set('Authorization', `JWT ${token}`);
    return fetchUtils.fetchJson(url, options);
};

const API_URL = process.env.REACT_APP_ADMIN_API_URL || 'http://localhost:3003/admin_api';

const dataProvider = restProvider(API_URL, httpClient);

const App = () => (
    <Admin authProvider={authProvider} dataProvider={dataProvider} theme={BackOfficeTheme} layout={Layouts}>
        <CustomRoutes>
            <Route path="/shop" element={<Configuration />} />
            <Route path="/bill" element={<Bill />} />
        </CustomRoutes>
        <Resource name="orders" {...orders} />
        <Resource name="users" {...users} />
        <Resource name="merchandises" {...merchandises} />
        <Resource name="photos" {...photos} />
        <Resource name="carts" {...carts} options={{ label: 'Prepared Carts' }} />
        <Resource name="events" {...events} />
        <Resource name="comments" {...comments} />
        <Resource name="occasions" {...occasions} />
        <Resource name="reviews" {...reviews} />
    </Admin>
);

export default App;
