import {
    Box,
    Card,
    Typography,
    Grid,
    CardContent,
    CardMedia,
    Dialog,
    TextField,
    Button,
    CardActions,
    DialogTitle,
} from '@mui/material';

import { memo, useState } from 'react';
import { ListBase, Loading, Pagination, useInput, useListContext, useRecordContext } from 'react-admin';

import _ from 'lodash';
import AddItem from './AddItem';
import MerchandiseInput from './MerchandiseInput';

const ItemsList = () => {
    const record = useRecordContext();
    const { field } = useInput({ source: 'itemsList' });

    function arrayMove(array: any, fromIndex: number, toIndex: number) {
        const newArray = [...array];
        const element = newArray[fromIndex];
        newArray.splice(fromIndex, 1);
        newArray.splice(toIndex, 0, element);
        return newArray;
    }

    const handleMoveITem = (index: number, moveToIndex: number) => {
        field.onChange(arrayMove(field.value, index, moveToIndex - 1));
    };

    return (
        <Box sx={{ width: '100%' }}>
            <ItemsListHeader
                lengthOfList={record.itemsList ? record.itemsList.length : 0}
                occasionName={record?.name}
                id={record?._id}
            />
            <ListBase resource="merchandises" perPage={12} filter={{ _id: { $in: field?.value || [] } }}>
                <ItemsListContent handleMoveITem={handleMoveITem} list={field.value} />
                <Pagination rowsPerPageOptions={[12]} />
            </ListBase>
        </Box>
    );
};

const ItemsListHeader = memo(
    ({ lengthOfList, occasionName, id }: { lengthOfList: number; occasionName: string; id: string }) => {
        return (
            <Card sx={{ padding: 3 }}>
                <Box textAlign="center">
                    <Typography variant="h5">{occasionName}</Typography>
                    <Typography component="div" variant="h2" sx={{ fontWeight: 'bold', flex: '1' }}>
                        {lengthOfList}
                    </Typography>
                    <Typography component="div" variant="h5">
                        item{`${lengthOfList > 1 ? 's' : ''}`}
                    </Typography>
                </Box>
                <Box display="flex" alignItems="center" flexDirection="column">
                    <AddItem id={id}>
                        <MerchandiseInput />
                    </AddItem>
                </Box>
            </Card>
        );
    },
    (prevProps, nextProps) => {
        return _.isEqual(prevProps, nextProps);
    },
);

interface RecordType {
    _id: string;
    name: string;
    itemImages: string[];
}

const ItemsListContent = memo(
    ({ handleMoveITem, list }: { handleMoveITem: (index: number, moveToIndex: number) => void; list: string[] }) => {
        const { data, isLoading } = useListContext();

        if (isLoading) return <Loading />;

        return (
            <Grid container spacing={2} sx={{ marginTop: '1em' }}>
                {data?.map((record: RecordType) => (
                    <ItemsListContentCard
                        key={record._id}
                        record={record}
                        index={list.indexOf(record._id)}
                        lengthOfList={list ? list.length : 0}
                        handleMoveItem={handleMoveITem}
                    />
                ))}
            </Grid>
        );
    },
    (prevProps, nextProps) => {
        return _.isEqual(prevProps, nextProps);
    },
);

const ItemsListContentCard = memo(
    ({
        record,
        index,
        handleMoveItem,
        lengthOfList,
    }: {
        record: RecordType;
        index: number;
        handleMoveItem: (index: number, moveToIndex: number) => void;
        lengthOfList: number;
    }) => {
        const [showDialog, setShowDialog] = useState<boolean>(false);
        const [moveToIndex, setMoveToIndex] = useState<number>(index + 1);

        const handleClick = () => {
            setShowDialog(true);
        };

        const handleCloseClick = () => {
            setShowDialog(false);
        };

        return (
            <>
                <Grid xs={12} sm={6} md={4} lg={3} xl={2} item>
                    <Card>
                        <Box
                            onClick={handleClick}
                            sx={{
                                cursor: 'pointer',
                                transition: 'transform .15s ease-in-out',
                                '&:hover': {
                                    transform: 'translateY(-0.5em)',
                                },
                            }}
                        >
                            <ItemsListContentCardMedia image={record?.itemImages?.[0]} />
                            <CardContent>
                                <Typography
                                    component="h2"
                                    align="center"
                                    sx={{
                                        fontWeight: 'bold',
                                        textOverflow: 'ellipsis',
                                        whiteSpace: 'nowrap',
                                        overflow: 'hidden',
                                    }}
                                >
                                    {record.name ?? 'No name'}
                                </Typography>
                                <Typography align="center">priority {index + 1}</Typography>
                            </CardContent>
                        </Box>
                    </Card>
                </Grid>

                <Dialog fullWidth maxWidth="sm" open={showDialog} onClose={handleCloseClick} aria-label="Create">
                    <Card>
                        <DialogTitle>Update The Priority Of MerChandise </DialogTitle>
                        <CardContent>
                            <TextField
                                label="Priotity"
                                type="number"
                                value={moveToIndex}
                                onChange={(event) => setMoveToIndex(+event.target.value)}
                                fullWidth
                                margin="normal"
                                inputProps={{ min: 1, max: lengthOfList }}
                            />
                        </CardContent>

                        <CardActions>
                            <Button onClick={handleCloseClick} color="secondary">
                                Cancel
                            </Button>
                            <Button
                                onClick={() => {
                                    handleMoveItem(index, moveToIndex);
                                    handleCloseClick();
                                }}
                                color="primary"
                            >
                                Move
                            </Button>
                        </CardActions>
                    </Card>
                </Dialog>
            </>
        );
    },
    (prevProps, nextProps) => {
        return _.isEqual(prevProps, nextProps);
    },
);

const ItemsListContentCardMedia = memo(
    ({ image }: { image: string }) => {
        return <CardMedia image={`${image}`} sx={{ height: 150, objectFit: 'contain' }} />;
    },
    (prevProps, nextProps) => {
        return _.isEqual(prevProps, nextProps);
    },
);

export default ItemsList;
