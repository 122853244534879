import { useState } from 'react';

const useMutation = ({ url, method = 'POST' }: any) => {
    const [state, setState] = useState({
        isLoading: false,
        error: '',
    });

    const fn = async (data: any, uploadDone: any) => {
        const token = localStorage.getItem('token');
        const handleUploadFinish = (imageUrl: any) => {
            const url = `https://kootooz-photos-pools.s3.amazonaws.com/${imageUrl}`;
            uploadDone(url);
        };

        setState((prev) => ({
            ...prev,
            isLoading: true,
        }));

        fetch(`http://localhost:3003/admin_api${url}`, {
            method,
            body: data,
            headers: {
                authorization: `JWT ${token}`,
            },
        })
            .then(async (res) => {
                const imageUrl = await res.json();
                await handleUploadFinish(imageUrl);
                setState({ isLoading: false, error: '' });
            })
            .catch((error) => setState({ isLoading: false, error: error.message }));
    };
    return { mutate: fn, ...state };
};

export default useMutation;
