import {
    BooleanField,
    CreateButton,
    Datagrid,
    DateField,
    ExportButton,
    List,
    NumberField,
    TextField,
    TopToolbar,
    useListContext,
} from 'react-admin';

const ListActions = () => {
    const { total, isLoading } = useListContext();
    return (
        <TopToolbar>
            <CreateButton />
            <ExportButton disabled={isLoading || total === 0} />
        </TopToolbar>
    );
};

const MerchandiseList = () => {
    return (
        <List actions={<ListActions />} sort={{ field: 'createdAt', order: '-1' }}>
            <Datagrid rowClick="edit">
                <TextField source="name" />
                <BooleanField source="active" />
                <NumberField label="Starting At" source="prices.base" />
                <DateField source="createdAt" />
            </Datagrid>
        </List>
    );
};

export default MerchandiseList;
