import { FC } from 'react';
import { DeleteButton, Toolbar } from 'react-admin';
import UpdateConfirmButton from './UpdateConfirmButton';

interface Props {
    noDelete?: boolean;
}

const ToolbarCustom: FC<Props> = ({ noDelete = false }) => (
    <Toolbar
        sx={{
            display: 'flex',
            justifyContent: 'space-between',
        }}
    >
        <UpdateConfirmButton />
        {!noDelete && <DeleteButton />}
    </Toolbar>
);
export default ToolbarCustom;
