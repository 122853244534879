import { Box, Card, Typography, Button, Grid, CardMedia, CardContent, CardActions } from '@mui/material';
import { memo, useCallback } from 'react';
import {
    ListBase,
    Pagination,
    RecordContextProvider,
    ReferenceField,
    useInput,
    useListContext,
    useRecordContext,
    useRedirect,
} from 'react-admin';
import { matchPath, useLocation } from 'react-router';
import CartItemCreate from './CartItemCreate';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import EditIcon from '@mui/icons-material/Edit';
import { CartItem as CartItemType } from '../types/cart';
import { ControllerRenderProps } from 'react-hook-form';
import { Item } from '../types/item';
import CartItemEdit from './CartItemEdit';

const CartItem = () => {
    const location = useLocation();
    const matchCreate = matchPath('/carts/:id/create', location.pathname);
    const matchEdit = matchPath('/carts/:id/:idCartItem/edit', location.pathname);

    const { field } = useInput({ source: 'items' });

    return (
        <Box sx={{ width: '100%' }}>
            <CartItemHeader itemCount={field?.value?.length} />
            <ListBase
                resource="cartitems"
                perPage={12}
                sort={{ field: 'createdAt', order: '-1' }}
                disableSyncWithLocation
            >
                <CartItemContent list={field} />
                <Pagination rowsPerPageOptions={[12]} />
            </ListBase>
            <CartItemCreate idCart={matchCreate?.params.id} open={!!matchCreate} />
            <CartItemEdit idCart={matchEdit?.params.id} idCartItem={matchEdit?.params.idCartItem} open={!!matchEdit} />
        </Box>
    );
};

const CartItemHeader = memo(({ itemCount }: { itemCount: number }) => {
    const redirect = useRedirect();

    const handleShowCreate = () => {
        redirect('./create');
    };

    return (
        <Card sx={{ padding: 3 }}>
            <Box textAlign="center">
                <Typography component="div" variant="h2" sx={{ fontWeight: 'bold', flex: '1' }}>
                    {itemCount}
                </Typography>
                <Typography component="div" variant="h5">
                    item{`${itemCount > 1 ? 's' : ''}`} selected
                </Typography>
            </Box>
            <Box display="flex" alignItems="center" flexDirection="column">
                <Button onClick={handleShowCreate} variant="outlined" startIcon={<AddCircleOutlineIcon />}>
                    create new
                </Button>
            </Box>
        </Card>
    );
});

const CartItemContent = memo(({ list }: { list: ControllerRenderProps }) => {
    const { data, isLoading } = useListContext<CartItemType>();

    const handleCardClick = useCallback(
        (id: string) => {
            const index = (list.value || []).findIndex((v: string) => v === id);
            const result =
                index < 0 ? [...list.value, id] : [...list.value.slice(0, index), ...list.value.slice(index + 1)];
            list.onChange(result);
        },
        [list],
    );

    if (isLoading) return null;

    return (
        <Grid container spacing={2} sx={{ marginTop: '1em' }}>
            {data?.map((record) => (
                <CartItemContentCard
                    onClick={handleCardClick}
                    active={list.value.indexOf(record.id) > -1}
                    key={record.id}
                    record={record}
                />
            ))}
        </Grid>
    );
});

const CartItemContentCard = memo(
    ({ onClick, active = false, record }: { onClick: (id: string) => void; active: boolean; record: CartItemType }) => {
        const redirect = useRedirect();

        const handleShowEdit = () => {
            redirect(`./${record.id}/edit`);
        };

        return (
            <RecordContextProvider value={record}>
                <Grid
                    xs={12}
                    sm={6}
                    md={4}
                    lg={3}
                    xl={2}
                    //====
                    item
                    sx={{
                        cursor: 'pointer',
                        transition: 'transform .15s ease-in-out',
                        '&:hover': {
                            transform: 'translateY(-0.5em)',
                        },
                    }}
                >
                    <Card
                        sx={[
                            active && {
                                backgroundColor: '#65e95a',
                            },
                        ]}
                    >
                        <Box onClick={() => onClick(record.id as string)}>
                            <ReferenceField source="item" reference="merchandises" link={false}>
                                <CartItemContentCardMedia />
                            </ReferenceField>
                            <CardContent>
                                <Typography component="h2" align="center" sx={{ fontWeight: 'bold' }}>
                                    {record.name ?? 'No name'}
                                </Typography>
                                <Typography align="center">Quantity: {record.quantity ?? '0'}</Typography>
                                <Typography align="center">{`$${record.price?.amount ?? 0} - ${
                                    record.price?.size ?? 'none'
                                }`}</Typography>
                            </CardContent>
                        </Box>
                        <CardActions
                            sx={{
                                display: 'flex',
                                justifyContent: 'space-around',
                                backgroundColor: '#fff',
                            }}
                        >
                            <Button onClick={handleShowEdit} variant="outlined" startIcon={<EditIcon />}>
                                Edit
                            </Button>
                        </CardActions>
                    </Card>
                </Grid>
            </RecordContextProvider>
        );
    },
);

const CartItemContentCardMedia = memo(() => {
    const record = useRecordContext<Item>();
    return <CardMedia image={`${record?.itemImages?.[0]}`} sx={{ height: 150, objectFit: 'contain' }} />;
});

export default CartItem;
