import { Box, Grid, IconButton, Stack, Typography, ImageList, ImageListItem } from '@mui/material';
import {
    EditBase,
    Labeled,
    ReferenceField,
    SimpleForm,
    DateField,
    TextField,
    TextInput,
    ReferenceArrayField,
    FunctionField,
} from 'react-admin';

import CloseIcon from '@mui/icons-material/Close';
import CommentEditToolbar from './CommentEditToolbar';

const CommentEdit = ({ ...props }: Props) => {
    const { id, onCancel } = props;
    return (
        <EditBase id={id}>
            <Box pt={5} width={{ xs: '100vW', sm: 400 }} mt={{ xs: 2, sm: 1 }}>
                <Stack direction="row" p={2}>
                    <Typography variant="h6" flex="1">
                        Comment Detail
                    </Typography>
                    <IconButton onClick={onCancel} size="small">
                        <CloseIcon />
                    </IconButton>
                </Stack>
                <SimpleForm sx={{ pt: 0, pb: 0 }} toolbar={<CommentEditToolbar />}>
                    <Grid container rowSpacing={1} mb={1}>
                        <Grid item xs={6}>
                            <Labeled>
                                <ReferenceField label="User Name" source="user" reference="users">
                                    <TextField source="name" />
                                </ReferenceField>
                            </Labeled>
                        </Grid>
                        <Grid item xs={6}>
                            <Labeled>
                                <DateField label="Date" source="createdAt" />
                            </Labeled>
                        </Grid>
                    </Grid>
                    <Grid item xs={6} mb={4}>
                        <Labeled>
                            <ReferenceField label="Event Name" source="event" reference="events">
                                <TextField source="name" />
                            </ReferenceField>
                        </Labeled>
                    </Grid>
                    <Grid item xs={6} mb={4}>
                        <Labeled>
                            <ReferenceField label="Shop name" source="shop" reference="shop">
                                <TextField source="name" />
                            </ReferenceField>
                        </Labeled>
                    </Grid>
                    <Grid item xs={6} mb={4}>
                        <Labeled>
                            <ReferenceField label="Order reviewed" source="order" reference="orders">
                                <TextField source="shortId" />
                            </ReferenceField>
                        </Labeled>
                    </Grid>
                    <Grid item xs={6} mb={4}>
                        <Labeled>
                            <ReferenceField label="Merchandise reviewed" source="item" reference="merchandise">
                                <TextField source="name" />
                            </ReferenceField>
                        </Labeled>
                    </Grid>
                    <TextInput
                        source="content"
                        fullWidth
                        multiline
                        rows={5}
                        InputProps={{
                            readOnly: true,
                        }}
                    />
                    <Grid item xs={6} mb={4}>
                        <Labeled>
                            <ReferenceArrayField label="Photos" source="photos" reference="photos">
                                <FunctionField
                                    render={(record: any) =>
                                        record && record.photos && record.photos.length ? (
                                            <ImageList>
                                                {record.photos.map(
                                                    (photo: { _id: string; url: string; name: string }) => (
                                                        <a href={photo.url || ''} target="_blank" rel="noreferrer">
                                                            <ImageListItem key={photo._id}>
                                                                <img src={photo.url} alt={photo.name} />
                                                            </ImageListItem>
                                                        </a>
                                                    ),
                                                )}
                                            </ImageList>
                                        ) : null
                                    }
                                ></FunctionField>
                            </ReferenceArrayField>
                        </Labeled>
                    </Grid>
                </SimpleForm>
            </Box>
        </EditBase>
    );
};

interface Props {
    onCancel: () => void;
    id: string;
}

export default CommentEdit;
