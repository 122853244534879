import { PropsWithChildren, useState } from 'react';
import { EditContextProvider, SimpleForm, useEditController } from 'react-admin';
import { Dialog, DialogActions, DialogTitle, Button } from '@mui/material';

import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import CancelIcon from '@mui/icons-material/Cancel';
import UpdateConfirmButton from '../components/UpdateConfirmButton';

interface Props extends PropsWithChildren {
    title?: string;
    id: string;
}

const EditDialogButton = (props: Props) => {
    const { title, id, children } = props;
    const [showDialog, setShowDialog] = useState(false);

    const onSuccess = () => {
        window.location.reload();
        setShowDialog(false);
    };

    const onError = () => {
        setShowDialog(false);
    };

    const DialogController = useEditController({
        resource: 'occasions',
        id,
        redirect: false,
        mutationMode: 'pessimistic',
        mutationOptions: { onSuccess, onError },
    });

    const handleClick = () => {
        setShowDialog(true);
    };

    const handleCloseClick = () => {
        setShowDialog(false);
    };

    return (
        <>
            <Button onClick={handleClick} variant="outlined" startIcon={<AddCircleOutlineIcon />}>
                add new
            </Button>

            <Dialog fullWidth open={showDialog} onClose={handleCloseClick} aria-label="Create">
                <DialogTitle>{title}</DialogTitle>
                <EditContextProvider value={DialogController}>
                    <SimpleForm warnWhenUnsavedChanges toolbar={false}>
                        {children}
                        <DialogActions
                            sx={{
                                width: '100%',
                                display: 'flex',
                                justifyItems: 'flex-end',
                            }}
                        >
                            <Button onClick={handleCloseClick}>
                                Cancel <CancelIcon />
                            </Button>
                            <UpdateConfirmButton />
                        </DialogActions>
                    </SimpleForm>
                </EditContextProvider>
            </Dialog>
        </>
    );
};

export default EditDialogButton;
