import PhotoList from './PhotoList';
import PhotoCreate from './PhotoCreate';
import PhotoEdit from './PhotoEdit';
import InsertPhotoIcon from '@mui/icons-material/InsertPhoto';
const photos = {
    list: PhotoList,
    create: PhotoCreate,
    edit: PhotoEdit,
    icon: InsertPhotoIcon,
};

export default photos;
