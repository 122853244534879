import {
    Datagrid,
    DateInput,
    ExportButton,
    FunctionField,
    List,
    NumberField,
    ReferenceField,
    SelectInput,
    TextField,
    TopToolbar,
    useListContext,
} from 'react-admin';

const ListActions = () => {
    const { total, isLoading } = useListContext();
    return (
        <TopToolbar>
            <ExportButton disabled={isLoading || total === 0} />
        </TopToolbar>
    );
};

const orderFilters = [
    <DateInput source="deliveryDate" alwaysOn />,
    <SelectInput
        source="status"
        choices={[
            { id: 'pending', name: 'pending' },
            { id: 'confirmed', name: 'confirmed' },
            { id: 'processing', name: 'processing' },
            { id: 'completed', name: 'completed' },
            { id: 'cancelled', name: 'cancelled' },
        ]}
        alwaysOn
    />,
];

const OrderList = () => {
    return (
        <List filters={orderFilters} actions={<ListActions />} sort={{ field: 'deliveryDate', order: '-1' }}>
            <Datagrid bulkActionButtons={false} rowClick="edit">
                <TextField source="deliveryDate" />
                <TextField source="status" sortable={false} />
                <ReferenceField label="User name" source="user" reference="users" sortable={false}>
                    <TextField source="name" />
                </ReferenceField>

                <ReferenceField label="Recipient Name" source="recipient" reference="recipients" sortable={false}>
                    <TextField source="name" />
                </ReferenceField>

                <ReferenceField label="Recipient Location" source="recipient" reference="recipients" sortable={false}>
                    <ReferenceField source="location" reference="locations" sortable={false}>
                        <FunctionField
                            render={(record: any) => `${record.address1}, ${record.city}, ${record.zipcode}`}
                        />
                    </ReferenceField>
                </ReferenceField>

                <ReferenceField source="cart" reference="carts" sortable={false}>
                    <NumberField source="total" options={{ style: 'currency', currency: 'USD' }} />
                </ReferenceField>
            </Datagrid>
        </List>
    );
};

export default OrderList;
