import { Table, TableBody, TableCell, TableHead, TableRow, Checkbox } from '@mui/material';
import { Box } from '@mui/system';
import { ChangeEvent, useCallback } from 'react';
import {
    Datagrid,
    DateField,
    Edit,
    FormTab,
    FunctionField,
    List,
    Pagination,
    required,
    RowClickFunction,
    SelectInput,
    TabbedForm,
    TextField,
    TextInput,
    useGetOne,
    useInput,
    useGetMany,
} from 'react-admin';
import { useFormContext } from 'react-hook-form';
import uniq from 'lodash/uniq';
import ToolbarCustom from '../components/ToolbarCustom';

const EventEdit = () => {
    return (
        <Edit>
            <TabbedForm toolbar={<ToolbarCustom />}>
                <FormTab label="Event" sx={{ maxWidth: '20em' }}>
                    <TextInput source="name" validate={required()} fullWidth />
                    <SelectInput
                        source="status"
                        choices={[
                            { id: 'pending', name: 'Pending' },
                            { id: 'inReview', name: 'In review' },
                            { id: 'published', name: 'Published' },
                            { id: 'rejected', name: 'Rejected' },
                        ]}
                        fullWidth
                        validate={required()}
                    />
                </FormTab>
                <FormTab label="Prepared cart" sx={{ maxWidth: '30em' }}>
                    <FunctionField
                        render={(record: any) => {
                            return <CartInput record={record} />;
                        }}
                    />
                </FormTab>
            </TabbedForm>
        </Edit>
    );
};

// CartItem
const CartInput = ({ record }: any) => {
    const { field: cartField } = useInput({ source: 'cart' });
    const { data: cartData } = useGetOne('carts', { id: cartField.value || '0000000' });
    const { data: cartItems } = useGetMany('cartitems', {
        ids: cartData?.items || [],
    });
    const { data: items, isSuccess } = useGetMany('merchandises', {
        ids: cartItems?.map((cartItem) => cartItem.item) || [],
    });
    const form = useFormContext();
    // form.register('items');

    let checkedList: Array<boolean> = [];
    let merchandise: Array<any> = [];
    cartItems?.forEach((cartItem, index) => {
        if (items && items.length) {
            items.forEach((item) => {
                if (cartItem.item === item._id) {
                    merchandise[index] = item;
                }
            });
        }
        if (record.items && record.items.includes(cartItem._id)) {
            checkedList[index] = true;
        }
    });

    const handleRowClick: RowClickFunction = useCallback((id, _resource, _record) => {
        cartField.onChange(id);
        return '#';
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleCheckCartItem = (e: ChangeEvent<HTMLInputElement>, cartItemId: string) => {
        const newItems = e.target.checked
            ? uniq([...record.items, cartItemId])
            : uniq(record.items.filter((itemId: string) => itemId !== cartItemId));

        form.setValue('items', newItems);
    };

    const merchandisePrice: number = merchandise.reduce((total, merchandise) => {
        total = total + (Number(merchandise.prices?.base) || 0);
        return total;
    }, 0);

    const totalPrice = cartData?.total ? cartData?.total : record?.total ? record?.total : 0;

    return (
        <>
            <Box mb={4}>
                <Table border={1}>
                    <TableHead>
                        <TableRow>
                            <TableCell colSpan={3}>Cart Items</TableCell>
                            <TableCell rowSpan={2}>Status</TableCell>
                            <TableCell rowSpan={2}>Total price</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell></TableCell>
                            <TableCell>Name</TableCell>
                            <TableCell>Price</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        <TableRow>
                            <TableCell>
                                {isSuccess && (
                                    <Checkbox
                                        defaultChecked={!!checkedList[0]}
                                        onChange={(e) => handleCheckCartItem(e, cartItems ? cartItems[0]?._id : '')}
                                    />
                                )}
                            </TableCell>
                            <TableCell>
                                {cartItems && cartItems[0]?.name
                                    ? cartItems[0].name
                                    : merchandise && merchandise[0]?.name
                                    ? merchandise[0].name
                                    : ''}
                            </TableCell>
                            <TableCell>
                                {cartItems && cartItems[0]?.price?.amount
                                    ? cartItems[0]?.price?.amount
                                    : merchandise && merchandise[0]?.prices?.base
                                    ? merchandise[0].prices.base
                                    : ''}
                            </TableCell>
                            <TableCell {...(cartData?.items?.length ? { rowSpan: cartData.items.length + 1 } : {})}>
                                {cartData?.status ? cartData?.status : record?.status}
                            </TableCell>
                            <TableCell {...(cartData?.items?.length ? { rowSpan: cartData.items.length + 1 } : {})}>
                                {totalPrice || merchandisePrice.toFixed(2)}{' '}
                            </TableCell>
                        </TableRow>
                        {cartItems &&
                            (() => {
                                merchandise = merchandise.splice(1);
                                if (!merchandise.length) return;
                                return merchandise.map((item, index) => (
                                    <TableRow key={cartItems[index]._id || index}>
                                        <TableCell>
                                            {isSuccess && (
                                                <Checkbox
                                                    defaultChecked={!!checkedList[index]}
                                                    onChange={(e) =>
                                                        handleCheckCartItem(e, cartItems[index]?._id || '')
                                                    }
                                                />
                                            )}
                                        </TableCell>
                                        <TableCell>
                                            {cartItems[index]?.name ? cartItems[index].name : item?.name || ''}
                                        </TableCell>
                                        <TableCell>
                                            {cartItems[index]?.price?.amount
                                                ? cartItems[index]?.price?.amount
                                                : item.prices?.base || ''}
                                        </TableCell>
                                    </TableRow>
                                ));
                            })()}
                    </TableBody>
                </Table>
            </Box>
            <List
                actions={false}
                resource="carts"
                pagination={<Pagination rowsPerPageOptions={[10]} />}
                disableSyncWithLocation
            >
                <Datagrid bulkActionButtons={false} rowClick={handleRowClick}>
                    <TextField fullWidth source="name" label="Cart name" />
                    <TextField fullWidth source="status" />
                    <DateField fullWidth source="createdAt" />
                    <FunctionField
                        fullWidth
                        label="Total cart item"
                        render={(record?: any, _source?: string | undefined) =>
                            record?.items?.length?.toString() || '0'
                        }
                    />
                </Datagrid>
            </List>
        </>
    );
};

export default EventEdit;
