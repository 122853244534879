import { Create } from 'react-admin';
import { CartForm } from './CartEdit';

const CartCreate = () => {
    return (
        <Create redirect="list">
            <CartForm />
        </Create>
    );
};

export default CartCreate;
