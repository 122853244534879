import { Card, CardMedia, Grid } from '@mui/material';
import { useCallback, useEffect, useState } from 'react';
import {
    BooleanField,
    Datagrid,
    List,
    Pagination,
    TextField,
    useGetOne,
    useInput,
    RowClickFunction,
} from 'react-admin';

const MerchandiseInput = () => {
    const { field: itemsListField } = useInput({ source: 'itemsList' });
    const [itemsList, setItemsList] = useState<string[]>([]);

    useEffect(() => {
        setItemsList(itemsListField.value);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const { data: itemData } = useGetOne('merchandises', {
        id: itemsListField.value[itemsList.length] || '5ea5dee1c3820e6cbe450e0d',
    });

    const handleRowClick: RowClickFunction = useCallback(
        (id) => {
            itemsListField.onChange([...itemsList, id]);

            return '#';
        },
        [itemsList, itemsListField],
    );

    const gridImage = (
        <Grid item xs={8} sx={{ height: '200px' }}>
            <Card sx={{ height: '100%' }}>
                {
                    <CardMedia
                        component="img"
                        image={`${itemData?.itemImages?.[0]}`}
                        sx={{ width: '100%', height: '100%', objectFit: 'contain' }}
                    />
                }
            </Card>
        </Grid>
    );

    return (
        <>
            <Grid container mb={3} spacing={2} display={itemsListField.value[itemsList.length] ? 'flex' : 'none'}>
                {gridImage}
            </Grid>

            <List
                resource="merchandises"
                actions={false}
                pagination={<Pagination rowsPerPageOptions={[10]} />}
                disableSyncWithLocation
                sx={{ width: '100%' }}
                filter={{ _id: { $nin: itemsList || [] } }}
            >
                <Datagrid bulkActionButtons={false} rowClick={handleRowClick}>
                    <TextField source="name" />
                    <BooleanField source="active" />
                </Datagrid>
            </List>
        </>
    );
};

export default MerchandiseInput;
