import { Button, LinearProgress, Typography } from '@mui/material';
import useMutation from '../hooks/useMutation';
import AddPhotoAlternateIcon from '@mui/icons-material/AddPhotoAlternate';

const URL = '/images';

const ImageInputS3 = (props: any) => {
    const { uploadDone } = props;
    const { mutate: uploadImage, isLoading: uploading, error: uploadError } = useMutation({ url: URL });

    const handleUpload = async (e: any) => {
        const file = e.target.files[0];
        const form = new FormData();
        form.append('image', file);
        await uploadImage(form, uploadDone);
    };

    return (
        <>
            <input
                color="primary"
                accept="image/*"
                type="file"
                onChange={handleUpload}
                id="icon-button-file"
                style={{ display: 'none' }}
            />
            <label htmlFor="icon-button-file">
                <Button
                    variant="contained"
                    component="span"
                    size="large"
                    color="primary"
                    disabled={uploading}
                    sx={{
                        paddingX: '70px',
                    }}
                >
                    <Typography variant="h6" sx={{ marginRight: '7px' }}>
                        Upload file
                    </Typography>
                    <AddPhotoAlternateIcon fontSize="medium" />
                </Button>

                {uploadError && (
                    <Typography sx={{ color: 'red' }}>Failed to save with error - {uploadError}</Typography>
                )}
                {uploading && <LinearProgress variant="indeterminate" sx={{ mt: 3 }} />}
            </label>
        </>
    );
};

export default ImageInputS3;
