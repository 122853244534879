import { Box, Stack } from '@mui/material';
import { Edit, required, SimpleForm, TextInput, useInput } from 'react-admin';
import ToolbarCustom from '../components/ToolbarCustom';
import ImageInputS3 from '../components/ImageInput';

const PhotoEdit = () => {
    return (
        <Edit>
            <SimpleForm toolbar={<ToolbarCustom />} sx={{ maxWidth: '50em' }}>
                <TextInput source="url" fullWidth validate={[required()]} disabled />
                <ImageForm source="url" />
            </SimpleForm>
        </Edit>
    );
};

const ImageForm = ({ source }: { source: string }) => {
    const { field } = useInput({ source });
    const handleUploadDone = (url: string) => {
        field.onChange(url);
    };

    return (
        <Stack spacing={3}>
            <ImageInputS3 uploadDone={handleUploadDone} />
            {field.value ? (
                <Box
                    component="img"
                    sx={{
                        maxWidth: { xs: 350, md: 250 },
                    }}
                    src={field.value}
                    srcSet={field.value}
                    loading="lazy"
                />
            ) : (
                ''
            )}
        </Stack>
    );
};

export default PhotoEdit;
